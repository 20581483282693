import styled from 'styled-components';

export const Image = styled.img`
    width: 110px;
    padding-left: 10px;
`;


export const MenuHeader = styled.ul`
    display: flex;
    align-items: center;
    justify-content: space-around;
    list-style: none;
    margin: 0 5px 0 0;
    padding: 0

    @media ( max-width :992px) {
        flex-direction: column;
        padding: 0;
        align-items: flex-start;
    }

    > li {
           
        > a {
            position: relative;
            display: inline-block;
            vertical-align: top;
            text-decoration: none;
            color: #222222;
            padding: 20px;
            text-align: left;
            margin-left: auto;
            margin-right: auto; 
            font-family: Montserrat, sans-serif;
        }

        &:hover {
            >a {
                color: #ffa100;
            }

            >ul {
                display: block
            }
            
        }

        &:active {
            >ul {
                display: block
            }
        }
    }
`;

export const DropdowmmMenu = styled.ul`
    list-style: none;
    position: absolute;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 0;
    display: none;

    @media ( max-width :992px) {
        position: relative;    
    }
    li {

        a {
            display: block;
            font-family: Montserrat, sans-serif;
            color: #222;
            padding: 10px 20px;
            text-decoration: none;
        }
        
        &:hover {
            a {
                color: #ffa100;
                text-decoration: none;
            }
        }
        
    }
`;

export const Search = styled.input`
    border: 1px solid transparent;
    background-color: red;
    font-family: 'Font awesome 5 pro 300', sans-serif;
    color: #333;
    width:100%;
`;

export const BurgerMenu = styled.div`
    display: none;
    width: 0;
    color: #ffa100;
    font-size: 16px;
    
    @media screen and (max-width: 992px) {
        display: flex;
        height:100%
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
    }
`;

export const Profile = styled.div`

    span {
        position: relative;
        display: flex;
        width: 150px;
        margin: 5px 0;
    }

    &:hover > .w-dropdown-list {
        display: flex!important;
        flex-direction: column;
    }

    .w-dropdown-list{
        background: #fff;
        border: 1px solid #ddd;
        border-radius: 8px;
        padding: 5px;
        overflow: hidden;
        min-width: auto;
        width: 120px;

        a {

            &:hover {
                text-decoration: none;
                color: #ffa100;
            }
        }
    }

    .mobile-menu-profile {
        display: none;
        .dropdown-list {
            border-radius: 8px;
            background: #000;
            
            button {
                color: #fff;
                padding: 5px;
            }
        }

        @media ( max-width :992px) {
            display: flex;
        }
    }

    .desktop-menu-profile {
        display: flex;
        
        @media ( max-width :992px) {
            display: none;
        }
    }

`;

export const MenuArea = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;

    @media ( max-width :992px) {
        position: absolute;
        right: 0;
        top: 60px;
        border-radius:16px
        background: #000;
        flex-direction: column;
        padding: 0;
        align-items: flex-start;
        height: 0;
        overflow: hidden;
        padding: 0 10px

        > ul > li > a {
            color: #fff!important;
        }
    }
`;