import React,{ useState } from 'react';
import { Row, Col } from 'reactstrap';
import { ProgressBox, ProgressBar, ProgressCourseBox } from './style';
import ProgressCourse from './ProgressCourse';
import Placeholder from '../../assets/images/placeholder.jpg';

const ProfileProgessCourse = (props) => {

    const [ show, setShow ] = useState(false); 

    const Progress = (props) => (
        <ProgressCourse
            image={props.course.image !== null ? props.course.image.urlPublica:Placeholder} 
            url={`/lms/courses/${props.course.id}`}
            title={props.course.title}
        />
    )

    return (
        <ProgressBox className={`mb-3 profile-progress-box ${show?'open':''}`}>
            <Row className='d-flex align-items-center progress-indicator'>
                <Col xs={11}>
                    <h3>{props.title}</h3>
                    <Row className='d-flex align-items-center'>
                        <Col xs={2} md={1} className='p-0 p-md-3'>
                            <h4>{props.cursos.length < 10 ? `0${props.cursos.length}`:props.cursos.length}</h4>
                        </Col>
                        <Col xs={10} md={11}>
                            <ProgressBar>
                                <div className="grafico1" style={{
                                    width:`${(props.cursos.length*100)/props.total}%`
                                }}/>
                            </ProgressBar>
                        </Col>
                    </Row>
                </Col>
                <Col xs={1} className='m-0 p-0' onClick={e => {setShow(!show)}}>
                    <i className="fal fa-chevron-down" />
                </Col>
            </Row>
            <ProgressCourseBox className='profile-courses'>
                {props.cursos.length > 0 &&
                    <>
                        {props.cursos.map(item =>(
                           item.hasOwnProperty('course') ? <Progress key={item.id} course={item.course}/> : <Progress key={item.id} course={item}/>
                        ))}
                    </>
                }
            </ProgressCourseBox>
        </ProgressBox>
    )
};

export default ProfileProgessCourse;
