import React, { useEffect, useState } from 'react';
import { PrimaryTitle } from '../../../styles/base';
import { Col, Row, Spinner } from 'reactstrap';
import { fetchClassroom } from '../../../services/endpoints/lms/courses/presencial';
import Base from '../../../theme/Base';
import CourseLine from  '../../../components/Course/Line';

const CoursePresencial = (props) => {

  const [ courses, setCourses ] = useState([]);
  const [ loading, setLoading ] = useState(true);
  
  useEffect(() => {
    fetchClassroom()
    .then((coursesPresencial) => {
      setCourses(coursesPresencial.data || [])
      setLoading(false);
    })
    .catch((err) => {
      console.log('Erro ao buscar cursos presenciais', err)
    })
  }, []);

  return (
    <Base>
      {loading === true ? 
        <Row className='m-0'>
          <Col xs={12} className='my-5 text-center'>
            <Spinner color="dark" />
          </Col>
        </Row>:
        <div className="w-container">
          <PrimaryTitle>Cursos <br/> Presenciais</PrimaryTitle>
          <Row className='mb-5'>
            {courses.map(course => (
              <Col xs={12} key={course.id}>
                <CourseLine
                  course={course}
                />
              </Col>
            ))}
          </Row>
        </div>
      }
    </Base>
  );
};

export default CoursePresencial; 
