import React, { useState, useEffect } from 'react';
import BannerItem from './BannerItem';
import { NextArrow, PrevArrow } from './style';
import { Row, Col, Spinner } from 'reactstrap';
import { fetchBannersByGroup } from '../../../services/endpoints/cms/banners';
import Slider from "react-slick";

const MainBanner = ({ props }) => {

  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(true);

  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <NextArrow id="next-question" onClick={onClick} className={`${className}`}></NextArrow>
    );
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <PrevArrow id="prev-question" onClick={onClick} className={` ${className}`}>Anterior</PrevArrow>
    );
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  const order = (a, b) => {
    let comparison = 0;
    if (a.position > b.position) {
      comparison = 1;
    } else if (a.position < b.position) {
      comparison = -1;
    }
    return comparison;
  }

  const getBannerHome = async () => {
    try {
      const response = await fetchBannersByGroup(1);
      const { data } = response;

      let temp = [];

      data.sort(order).forEach(item => {
        if (item.active) {
          temp.push(item);
        }
      });

      setBanners(temp);
      setLoading(false);
    } catch (error) {
      console.error('MainBanner.getBannerHome', error);
    }
  }

  useEffect(() => {
    getBannerHome();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {loading === true ?
        <Row className='m-0'>
          <Col xs={12} className='my-5 text-center'>
            <Spinner color="dark" />
          </Col>
        </Row>
        :
        <>
          {banners.length ?
            <Slider {...settings} className='slider-home'>
              {banners.map(banner => (
                <BannerItem
                  key={banner.id}
                  image={banner.file.urlPublica}
                  imageMobile={banner.file_mobile ? banner.file_mobile.urlPublica : banner.file.urlPublica}
                  title={banner.title}
                  description={banner.description}
                  urlDescription={banner.button_text}
                  url={banner.url}
                  urlTarget={banner.target}
                />
              ))}
            </Slider>
            :
            <></>
          }
        </>
      }
    </>
  );
};

export default MainBanner;