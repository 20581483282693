import React from 'react';
import { Card, ImageCont, BtnAvaliable, BtnUnavaliable } from './style';
import { getReward } from '../../services/endpoints/gamification/rewards';

const PremioCard = (props) => {

    const getAward = id => {
        getReward({
            "reward": {
                "id": id
            }
        })
        .then(res => {
            props.setModal(true)
            props.setRefresh(prev => !prev)
        })
        .catch((err) => {
            console.log('Erro ao registrar premio', err);
        })
    }
    
    return(
        <Card>
            <ImageCont>
                <img src={props.image} alt=""/>
            </ImageCont>
            <p>{props.name}</p>
            <h4>{props.cost}</h4>
            {props.status === 'available'? (
                props.amount >= props.cost ? (
                   <BtnAvaliable onClick={e => getAward(props.id)}>Trocar pontos</BtnAvaliable>
                ):(
                    <BtnUnavaliable>Pontos insuficientes</BtnUnavaliable>
                )
            ):(
                <BtnUnavaliable>{props.status === 'CONCLUIDO'? 'Concluido': 'Entrege'}</BtnUnavaliable>
            )}
        </Card>
    );
}

export default PremioCard;