import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { DropdowmmMenu } from './styles'

const MenuItem = ({item}) => {

    return(
        <li>
            <Link to={item.url} title={item.title} className={item.url === window.location.pathname ? 'menu-active':''}>{item.title} {item.menu_items.length > 0?<i className="fal fa-chevron-down"/>:''}</Link>
            {item.menu_items.length > 0 && 
                <DropdowmmMenu>
                    {item.menu_items.map(menu_item => 
                        <MenuItem key={menu_item.id} item={menu_item}/>
                    )}
                </DropdowmmMenu>
            }
        </li>
    )
};

MenuItem.propTypes = {
    item: PropTypes.object.isRequired
}

export default MenuItem;