import api from '../../api'
// import { getCurrentUser } from '../../../utils/getUser'

export const getFieldValues = (id) => {
  return api.get('/general/field_values', {
    params: {
      user_iri: id,
    }
  })
}

export const getFieldValuesByName = (name, id) => {
  return api.get('/general/field_values', {
    params: {
      user_iri: id,
      'match[field.title]': name
    }
  })
}

export const getFieldByName = (name) => {
  return api.get('/general/fields', {
    params: {
      title: name
    }
  })
}

export const uploadAvatar = (id, data) => {
  return api.post(`/general/field_values/${id}/upload/file`, data, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

export const setFieldsValues = (data) => {
  return api.post('/general/fieldValuesBatch', data)
}
