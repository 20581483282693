import api from '../../api';

const fetchCursosNaoIniciados = (params) => api.get('/lms/courses/not_started', {
    params: {
        ...params,
    },
    active: true
});

export {
    fetchCursosNaoIniciados,
};