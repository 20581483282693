import React, { useEffect, useState } from 'react';
import { PrimaryTitle } from '../../styles/base';
import Base from '../../theme/Base';
import { fetchSettings } from '../../services/endpoints/settings/settings';
import { fetchFlarumLogin } from '../../services/endpoints/flarum_sso/login';

const Forum = (props) => {

  const [ iframeUrl, setIframeUrl ] = useState('');
  const [ loading, setLoading ] = useState(true);

  const setForumToken = () => {
    fetchFlarumLogin()
    .then(res => {
      document.cookie = `${res.data.userCookie.split(';')[0]}; Domain=${process.env.REACT_APP_DOMAIN}`;
      document.cookie = `locale=pt-BR; Domain=${process.env.REACT_APP_DOMAIN}`;
      setLoading(false);
    })
    .catch(error => {
      console.log('Erro ao armazenar dados de usuario no forum', error)
    });
  }

  useEffect(()=>{
    fetchSettings('flarum_url')
    .then(setting => {
      setIframeUrl(setting.data.value); 
      setForumToken();
    })
    .catch(error => {
      console.log('Erro ao buscar a url do forum', error)
    });   
  },[])
    return (
    <Base>
      <div className="w-container my-5">
        <PrimaryTitle>Fórum</PrimaryTitle>
        {!loading && <iframe title='forum' className='forum-frame' src={iframeUrl}/>}
      </div>
    </Base>
  );
};

export default Forum;