import React, { useEffect, useState } from 'react';
import { MenuHeader, MenuArea } from './styles'
import { fetchMenu } from '../../services/endpoints/cms/menus';
import MenuItem from './MenuItem';
import SearchButtom from './SearchButtom';
import ProfileInfo from './ProfileInfo';

const MainMenu = (props) => {
  
  const [ menu, setMenu ] = useState([]);

  useEffect( ()=> {
    fetchMenu({
      slug:'principal'
    })
    .then((mainmenu) => {
      setMenu(mainmenu.data[0].menu_items)
    })
    .catch((err) => {
        console.log('Erro ao buscar menus', err)
    })
  }, [])
  
  return (
    <MenuArea 
      className={props.state ? 'menu-open':''}
    >
      <MenuHeader>
        {menu.map(item => (
          <MenuItem key={item.id} item={item}/>
        ))}
      </MenuHeader>
      <div className='d-none d-lg-flex'><SearchButtom/></div>
      <ProfileInfo
        nome={props.name}
      />
    </MenuArea>
  );
};

export default MainMenu;