import React, { useState } from 'react';

const SearchButtom = () => {

    const [searchInput, setSearchInput] = useState(false);
    const [searchContent, setSearchContent] = useState('');

    const verifyContent = () => {
        if (!searchContent.length) {
            setSearchInput(!searchInput);
        }
        else {
            window.location = `/search${searchContent}`;
        }
    }

    const searchAction = (e) => {
        e.preventDefault();
        verifyContent();
    };

    const enterSub = e => {
        if (e.key === "Enter") {
            verifyContent();
        }
    }

    return (
        <div className={`search ${searchInput ? 'active' : ''}`}>
            <div className="search-form">
                <input
                    onChange={e => setSearchContent("?search=" + e.target.value)}
                    name="query"
                    placeholder="pesquisar..."
                    onKeyUp={e => enterSub(e)}
                />

                <button onClick={e => searchAction(e)} type='submit'>
                    <i className="far fa-search" />
                </button>
            </div>
        </div>
    );
}

export default SearchButtom;