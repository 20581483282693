import React, { useState } from 'react';
import Base from '../../theme/Base';
import { PrimaryTitle } from '../../styles/base';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Row, Col, Modal } from 'reactstrap';
import { sendContact } from '../../services/endpoints/cms/contact';
import { SETTINGS } from '../../config/settings';
import { Description } from './style';

const Contact = () => {

  const [ disabled, setDisabled ] = useState(true);
  const [ modalContent, setModalContent ] = useState(true);
  const [ modal, setModal ] = useState(false);
  const [ mediaIds, setMediaIds ] = useState('')

  const toggle = () => window.location.href = '/';

  const SignupSchema = Yup.object().shape({
    name: Yup.string()
      .required('Campo obrigatório'),
    email: Yup.string()
      .email('E-mail inválido')
      .required('Campo obrigatório'),
    mensage: Yup.string()
      .required('Campo obrigatório'),
  });
  
  function getParams(url) {
    const params = {};
    const parser = document.createElement('a');

    parser.href = url;

    const query = parser.search.substring(1);
    const vars = query.split('&');

    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      const v = decodeURIComponent(pair[1]);
      params[pair[0]] = isNaN(+v) ? v : +v;
    }

    return params;
  }

  const params = getParams(window.location.href);
  const script = document.createElement('script');

  script.addEventListener('load', () => {
    // renaming
    if (!params.sambaUploadKey && params.uploadKey) {
      params.sambaUploadKey = params.uploadKey;
      delete params.uploadKey;
    }

    const config = Object.assign({
      sambaUploadKey: SETTINGS.sambaUploaderKey,
      retries: 200,
      partSize: 6e+6,
      maxItems: 1,
      templateContainer: document.querySelector('.contato-file'),
      log: false
    }, params);

    // uploader
    const sambaUploaderWithTemplate = new window.SambaUploader(config);
    sambaUploaderWithTemplate.on('error', event => console.error(event));

    sambaUploaderWithTemplate.on('complete', event => {
      setMediaIds(event.mediaId);
      setDisabled(!disabled);
    });

    document.querySelector('.contato-file button').classList.add('btn-upload-file')
    document.querySelector('.contato-file button').type = "button";

  });

  script.type = 'text/javascript';
  script.src = params.scriptUrl || '//static.sambavideos.sambatech.com/sambauploader/latest/samba.uploader.min.js';
  document.querySelector('head').appendChild(script);

  return(
    <Base>
      <div className="w-container my-5">
        <PrimaryTitle>Multiplicador <br/> de conhecimento</PrimaryTitle>
        <Description>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</Description>
        <Formik
          initialValues={{
            name: '',
            email: '',
            mensage: '',
            video: ''
          }}
          validationSchema={SignupSchema}
          onSubmit={values => {
            sendContact({
              "content": `<p>${values.mensage}</p> <p>&nbsp;</p>\r\n<p> Nome: <b>${values.name}</b>,</p> \r\n<p> E-mail: <b>${values.email}</b></p>` ,
              "subject": '',
              "user_iri": atob(localStorage.getItem(`user-endpoint`)),
              "ip": "0.0.0.0",
              "user_agent": navigator.userAgent,
              "media_ids": mediaIds
            })
            .then(() => {
              setModalContent('Mensagem envidada. Obrigado pelo contato.');
              setModal(!modal);
            })
            .catch(() => {
              setModalContent('Mensagem não envidada. Tente novament mais tarde.');
              setModal(!modal);
            })
          }}
        >
        {({ errors, touched }) => (
          <Form>
            <Row className='my-5 form-contato'>
              <Col xs={12} md={10} className='mb-4'>
                <Row>
                  <Col xs={12} md={6} className='d-flex flex-column contato-input px-0 px-md-3'>
                    <label htmlFor="name">Nome</label>
                    <Field name="name" placeholder="Nome" />
                    {errors.name && touched.name ? (<small>{errors.name}</small>) : null}
                  </Col>
                  <Col xs={12} md={6} className='d-flex flex-column contato-input px-0 px-md-3'>
                    <label htmlFor="email">E-mail</label>
                    <Field name="email" type="email" placeholder="E-mail" />
                    {errors.email && touched.email ? (<small>{errors.email}</small>) : null}
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={10} className='d-flex flex-column contato-text-area mb-5'>
                <label htmlFor="mensage">Mensagem</label>
                <Field name="mensage" component="textarea" rows="4" placeholder="Deixe uma mensagem"/>
                {errors.mensage && touched.mensage ? (<small>{errors.mensage}</small>) : null}
              </Col>
              <Col xs={12} md={10} className='d-flex flex-column mb-5 contato-file'>
              </Col>
              <Col xs={12} md={10}>
                <button type="submit" disabled={disabled}>Enviar</button>
              </Col>
            </Row>
          </Form>
        )}
        </Formik>
      </div>
      <Modal isOpen={modal} toggle={toggle} className='modal-curso-presencial ' centered={true}>
        <i className="fas fa-times close-classroom-modal" onClick={toggle} />
        <p className='px-2 text-center'>{modalContent}</p>
      </Modal>
    </Base>
  )
};

export default Contact;