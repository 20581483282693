import api from '../../api';

const fetchFeatureGroups = (params) => api.get('/cms/feature_groups', {
    params: {
        ...params,
    },
});

export {
    fetchFeatureGroups
};