import api from '../../api';

const fetchPerformedExams =(params) => api.get(`/lms/exam_applications`, {
    params: {
        ...params,
    },
});

const fetchProvas = (id, params) => api.get(`/lms/exams/${id}`, {
    params: {
        ...params,
    },
});

const postProvas = (id, answers, object_id) => api.post(`/lms/exams/${id}/submission?${object_id}`,answers)

export {
    fetchPerformedExams,
    fetchProvas,
    postProvas,
};