import React, { useState, useEffect } from 'react';
import { PrimaryTitle } from '../../styles/base';
import { Row, Col } from 'reactstrap';
import { LiveFrame, Frame, LiveInfo } from './style';

import Base from '../../theme/Base';

const CourseOnline = (props) => {

  const [ live, setLive ] = useState({});
  const [ idLive, setIdLive ] = useState('');
  const [ online, setOnline ] = useState(false);

  const convertDefaultDate = (date) => {
    const parts = date.split('/');
    const parts2 = parts[2].split(' ');
    const parts3 = parts2[1].split(':');
    const newDate = new Date(parts2[0], parts[1] - 1, parts[0], parts3[0], parts3[1], parts3[2]);
    return newDate.getTime();
  };
  
  useEffect(() => {
    const aovivo = [{
      "id": 1,
      "url": "https://www.youtube.com/watch?v=3_6RTTpWyaA&feature=emb_logo",
      "user_iri": "/users/38",
      "title": "Lorem Ipsum is simply dummy text of the dummy",
      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.",
      "started_at": "25/03/2020 10:44:00",
      "ended_at": "30/03/2020 12:00:00"
    }]

    const urlLive = new URL(aovivo[0].url);
    let now = new Date().getTime();

    setLive(aovivo[0] || []);
    setIdLive(urlLive.searchParams.get("v") || '');

    if (convertDefaultDate(aovivo[0].started_at) <= now && convertDefaultDate(aovivo[0].ended_at) >= now) {
      setOnline(true)
    }
  },[]);

  return (
    <Base>
      <div className="w-container">
        <PrimaryTitle>Aula<br/>ao vivo</PrimaryTitle>
        <Row>
            {online ?
              <Col xs={12}>
                <LiveFrame>
                  <Frame 
                    src={`https://www.youtube.com/embed/${idLive}`}
                    frameborder="0" 
                    allowfullscreen>
                  </Frame>
                </LiveFrame>
                <LiveInfo>
                  <h2>{live.title}</h2>
                  <p dangerouslySetInnerHTML={{__html: live.description}}/>
                </LiveInfo>
              </Col>
            :
              <Col xs={12} className='my-2 text-center'>
                <h2>Live offline</h2>
              </Col>
            }
        </Row>
      </div>
    </Base>
  );
};

export default CourseOnline;