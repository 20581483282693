import React, { useState, useEffect } from 'react';
import { PrimaryTitle } from '../../styles/base';
import { fetchRankingList } from '../../services/endpoints/gamification/ranking';
import RankItem from '../../components/Ranking/RankItem';
import Base from '../../theme/Base';
import { Col, Row, Spinner } from 'reactstrap';

const Ranking = (props) => {

  const [ranking, setRanking] = useState([]);
  const [loading, setLoading] = useState(true);
  const [atualUser, setAtualUser] = useState([]);

  const getAvatar = (item) => {
    if (item.hasOwnProperty('user') &&
      item.user.hasOwnProperty('profile') &&
      item.user.profile.hasOwnProperty('avatar') &&
      item.user.profile.avatar.hasOwnProperty('file') &&
      item.user.profile.avatar.file &&
      item.user.profile.avatar.file.hasOwnProperty('urlPublica')
    ) {
      return item.user.profile.avatar.file.urlPublica;
    }
    return null;
  }

  const getRankingList = async () => {
    try {
      const response = await fetchRankingList();
      const { data } = response;
      let temp = [];

      if (data) {
        data.slice(0, 3).forEach(item => {
          return temp.push({
            position: item.position,
            avatar: getAvatar(item),
            name: item.hasOwnProperty('user') ? item.user.name : 'Não informado',
            score: item.amount,
            authenticated: item.user_iri === atob(localStorage.getItem(`user-endpoint`)) ? true : false
          })
        });

        setAtualUser(data.filter(item => {
          return (
            item.user_iri === atob(localStorage.getItem("user-endpoint")) &&
            item.position > 3
          )
        }))
      }

      return temp;

    } catch (error) {
      console.error('Ranking.getRankingList', error);
      return [];
    }
  }

  console.log(atualUser);

  useEffect(() => {
    const loadContent = async () => {
      setRanking(await getRankingList());
      setLoading(false);
    }
    loadContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
    <Base>
      {loading ?
        <Row className='m-0'>
          <Col xs={12} className='my-5 text-center'>
            <Spinner color="dark" />
          </Col>
        </Row>
        :
        <>
          <div className="w-container my-5">
            <PrimaryTitle>Ranking</PrimaryTitle>
            {ranking.length > 0 && ranking.map(rank => (
              <RankItem
                key={rank.position}
                position={rank.position}
                avatar={rank.avatar}
                name={rank.name}
                score={rank.score}
                authenticated={rank.authenticated}
              />
            ))}
            {!!atualUser.length &&
              <RankItem
                key={atualUser[0].position}
                position={atualUser[0].position}
                avatar={getAvatar(atualUser[0])}
                name={atualUser[0].hasOwnProperty('user') ? atualUser[0].user.name : 'Não informado'}
                score={atualUser[0].score}
              />
            }
          </div>
        </>
      }
    </Base>
  );
};

export default Ranking;