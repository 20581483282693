import styled from 'styled-components';

export const AvatarBox = styled.div`
  
`;

export const AvatarContent = styled.div`
  display: flex;
  width: 130px;
  height: 130px;
  align-items: center;
  border-radius: 50%;
  max-width: 100%;
  overflow: hidden;
  position: relative;

  &:hover > div{
    color: white;
    background: #0000008c;
    cursor: pointer;
    pointer-events: all;
  }
`;

export const Avatar = styled.img`
  object-fit: cover;
  height: 100%;
`;

export const AvatarForm = styled.div`
  cursor: pointer;
  pointer-events: none;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left:0;
  right: 0;
  justify-content: center;
  align-items: center;
  color: transparent;
`;

export const AvatarUploadText = styled.div`
  
`;

export const AvatarUploadFile = styled.input`
  position: absolute;
  cursor: pointer;
  top: 0;
  opacity: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Modal = styled.div`
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

export const ModalContent = styled.div`
  position: relative;
  width: 100%;
  height: 480px;
  max-width: 480px;
  max-height: 480px;
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
`;
export const ModalBody = styled.div`
  
`;

export const ModalClose = styled.div`
  position: absolute;
  z-index: 9;
  width: 40px;
  line-height: 34px;
  height: 40px;
  background: #000;
  text-align: center;
  color: #fff;
  font-size: 20px;
  border-radius: 50%;
  top: -20px;
  right: -20px;
  border: 2px solid #fff;
  font-family: monospace;
  cursor: pointer;
  &:hover{
    background-color: #cc2f48;
  }
`;

export const CropperContent = styled.div`
  position: relative;
  height: 240px;
  margin-bottom: 15px;
  border-radius: 5px;
  overflow: hidden;
`;

export const CropperSlider = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

export const CropperText = styled.div`
  margin-right: 15px;
  font-size: 12px;
  width: 50px;
  text-align: right;
`;

export const ButtonUpload = styled.div`
  display: flex;
  cursor: pointer;
  background: #211e2a;
  color: #ffffff;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 100%;
  float: right;
  max-width: 170px;
  &:hover{
    background-color: #cc2f48;
  }
`;
