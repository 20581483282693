import styled from 'styled-components';

export const PrevArrow = styled.button`
    position: absolute!important;
    top: unset!important;
    bottom: -20px!important;
    left: 0px!important;
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
    border-radius: 10px!important;
    background-color: #ffa100!important;
    color: #000!important;
    font-weight: 500!important;
    text-align: center!important;
    font-size: 14px!important;
    max-width: 150px!important;
    height: 40px!important;
`;
export const NextArrow = styled.button`
    position: absolute!important;
    top: unset!important;
    bottom: -20px!important;
    right: 0px!important;
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
    border-radius: 10px!important;
    background-color: #ffa100!important;
    color: #000!important;
    font-weight: 500!important;
    text-align: center!important;
    font-size: 14px!important;
    max-width: 150px!important;
    height: 40px!important;
`;

export const ContentQuetion = styled.div`
 
    .inputGroup {
        background-color: #fff;
        display: block;
        margin: 15px 0;
        position: relative;

        h3 {
          font-size:18px
        }
    
        label {
          padding: 12px 65px 12px;
          width: 100%;
          display: block;
          text-align: left;
          color: #3C454C;
          cursor: pointer;
          position: relative;
          z-index: 2;
          transition: color 200ms ease-in;
          overflow: hidden;

          p {
              margin: 0;
              font-weight: 400;
          }
    
          &:before {
            width: 100%;
            height: 1px;
            border-radius: 50%;
            content: '';
            background-color: #eeeeee;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) scale3d(1, 1, 1);
            transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
            opacity: 0;
            z-index: -1;
          }
    
          &:after {
            width: 32px;
            height: 32px;
            content: '';
            border: 2px solid #D1D7DC;
            background-color: #fff;
            background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
            background-repeat: no-repeat;
            background-position: 2px 3px;
            border-radius: 50%;
            z-index: 2;
            position: absolute;
            left: 15px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            transition: all 200ms ease-in;
          }
        }
    
        input:checked ~ label {
          color: #ffa100;
          font-weight:600;
    
          &:before {
            transform: translate(-50%, -50%) scale3d(56, 56, 1);
            opacity: 1;
          }
    
          &:after {
            background-color: #ffa100;
            border-color: #ffa100;
          }
        }
    
        input {
          width: 32px;
          height: 32px;
          order: 1;
          z-index: 2;
          position: absolute;
          left: 15px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          visibility: hidden;
        }
      }
    
    .form {
      padding: 0 16px;
      max-width: 550px;
      margin: 50px auto;
      font-size: 18px;
      font-weight: 600;
      line-height: 36px;
    }
    
    body {
      background-color: #D1D7DC;
      font-family: 'Fira Sans', sans-serif;
    }
    
    *,
    *::before,
    *::after {
      box-sizing: inherit;
    }
    
    html {
      box-sizing: border-box;
    }
    
    code {
      background-color: #9AA3AC;
      padding: 0 8px;
    }
    
`; 

export const Question = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 25px 15px;
    border-radius: 8px;
    box-shadow: 0 10px 16px -8px rgba(0, 0, 0, 0.15);
    transition: box-shadow 400ms ease, transform 400ms ease;
    cursor: pointer;

    @media ( max-width: 768px) {
        justify-content: center
    }

    h2 {
        color: #0f4dbc;
        font-size: 18px;
        font-weight: 700;
        margin: 0;
    }

    h3 {
        margin: 40px 0;
        color: #333;
        font-size: 16px;
        line-height: 1.6em;
        font-weight: 700;
        text-align: left;
    }

    &:hover {
        border: 1px solid transparent;
        box-shadow: 0 10px 20px 0 rgba(14, 14, 14, 0.15);
        transform: scale(1.02);
    }
`;

export const Alternatives = styled.div`
    overflow: hidden;
`;

export const QuestionAlternative = styled.div`
    margin-bottom: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(221, 221, 221, 0.8);
    border-radius: 8px;
    transform: translate(0px, 0px);
    display: flex;
    align-items: center;
    padding: 0 10px;
    font-weight: normal;

    label {
        margin: 0;
        margin-left: 6px;
        padding: 10px 0;
        cursor: pointer;
        width: 100%;
        font-weight: normal;
        font-size: 14px;
    }

    p {
        margin:0;
    }

    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }

    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label
    {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: #666;
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 15px;
        height: 15px;
        border: 1px solid #ddd;
        border-radius: 100%;
        background: #fff;
    }

    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
        content: '';
        width: 15px;
        height: 15px;
        background: #fff;
        position: absolute;
        top: 50%;
        left: 0;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
        transform: translateY(-50%)!important;
        border: solid 4px #3898ec;
    }
    [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
`;