import api from '../../api';

const fetchPages = (params) => api.get('/cms/pages', {
    params: {
        ...params,
    },
});

export {
    fetchPages
};