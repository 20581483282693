import React from 'react';
import { Slide, SlideImage, SlideImageMobile, SlideInfo, SlideBtn, SideInUpFast, SideInUpSlow, SlideImgContent } from './style';

const BannerItem = (props) => {

    return (
        <Slide className={`bannerhome ${!!props.title ? 'mask-text' : ''}`}>
            <SlideImgContent>
                <SlideImage src={props.image} />
                <SlideImageMobile src={props.imageMobile} />
            </SlideImgContent>
            <SlideInfo>
                <SideInUpFast>
                    <h1>{props.title}</h1>
                </SideInUpFast>
                <SideInUpSlow>
                    <div dangerouslySetInnerHTML={{ __html: props.description }} />
                    {props.urlDescription ? <SlideBtn target={props.urlTarget} href={props.url} >{props.urlDescription}</SlideBtn> : ''}
                </SideInUpSlow>
            </SlideInfo>
        </Slide>
    )
};

export default BannerItem; 