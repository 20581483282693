import React from 'react';
import { LinkCourse, DivCourse, CardHeader, CardBody, ProgressText, ProgressBar } from './style';

const CourseCard = (props) => {

    let progress_text = ''

    switch (props.progress) {
        case 0:
            progress_text = 'Não iniciado'
            break;
        case 100:
            progress_text = 'Concluído'
            break;
        default:
            progress_text = `${props.progress}%`;
            break;
    }


    const ContentCard = () => (
        <>
            <CardHeader>
                <div>
                    <img src={props.image} alt={props.title} />
                    <small>{props.duration}</small>
                    <i className="fas fa-play-circle" />

                    {props.url !== undefined && props.progress !== undefined &&
                        <>
                            <ProgressText>{progress_text}</ProgressText>
                            <ProgressBar w={props.progress}>
                                <div />
                            </ProgressBar>
                        </>
                    }

                </div>
            </CardHeader>
            <CardBody>
                {props.tag && props.tags.length > 0 &&
                    <>
                        {props.tags.map((tag, index) =>
                            <small key={`tag-${index}`}>{tag.category.title}{props.tags.length - 1 === index ? '' : ', '}</small>
                        )}
                    </>
                }
                <h3>{props.title}</h3>
            </CardBody>
        </>
    );

    return (
        <>
            {props.url !== undefined ?
                <LinkCourse to={props.url} >
                    <ContentCard />
                </LinkCourse>
                :
                <DivCourse to={props.url} >
                    <ContentCard />
                </DivCourse>
            }
        </>
    )
};

export default CourseCard;