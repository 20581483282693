/** @format */

import React, { useState, useEffect } from "react";
import { PrimaryTitle } from "../../styles/base";
import Base from "../../theme/Base";
import { fetchGeneralSearch } from "../../services/endpoints/lms/search";
import { Col, Row, Spinner } from "reactstrap";
import CourseCard from "../../components/Course/Card";
import Placeholder from "../../assets/images/placeholder.jpg";

const Search = (props) => {
  const [courses, setCourses] = useState([]);
  const [trails, setTrails] = useState([]);
  const [loading, setLoading] = useState(true);

  const getImage = (item) => {
    if (item.thumbnail) {
      return item.thumbnail.urlPublica;
    }
    if (
      item.trails_courses.length &&
      item.trails_courses[0].hasOwnProperty("course") &&
      item.trails_courses[0].course &&
      item.trails_courses[0].course.hasOwnProperty("image") &&
      item.trails_courses[0].course.image !== null &&
      item.trails_courses[0].course.image.hasOwnProperty("urlPublica")
    ) {
      return item.trails_courses[0].course.image.urlPublica;
    }
    return Placeholder;
  };

  const getQuantity = (arr, value) => {
    let quantity = 0;
    arr.forEach((item) => {
      if (item === value) {
        quantity++;
      }
    });
    return quantity;
  };

  const getTrailMeta = (trail) => {
    let temp = [];
    !!trail &&
      trail.forEach((item) => {
        let tempProgress = [];
        item.trails_courses.forEach((item) => {
          if (item.course && !!item.course.length && !!item.course.course_stats.length) {
            tempProgress.push(item.course.course_stats[0].progress);
          }
        });
        let trailProgress = (getQuantity(tempProgress, 100) * 100) / item.trails_courses.length;

        temp.push({
          id: item.id,
          image: getImage(item),
          title: item.title,
          category_courses: item.trails_courses.length > 0 ? item.trails_courses[0].course.course_categories : [],
          progress: Math.round(trailProgress),
        });
      });
    return temp;
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchGeneralSearch(`${window.location.search.replace("?search=", "")}`);
        if (response && response.data) {
          if(!!response.data.courses)setCourses(response.data.courses.filter(item => (item.off_trails)) || []);
          if(!!response.data.trails)setTrails(getTrailMeta(response.data.trails) || []);
          setLoading(false);
        }
      } catch (error) {
        console.error("Search.fetchGeneralSearch", error);
      }
    })();
  }, []);

  return (
    <Base>
      {loading === true ? (
        <Row className="m-0">
          <Col xs={12} className="my-5 text-center">
            <Spinner color="dark" />
          </Col>
        </Row>
      ) : (
        <>
          <div className="w-container mt-5">
            <PrimaryTitle>
              Resultados
              <br /> da busca
            </PrimaryTitle>
          </div>
          {courses.length < 1 && trails.length < 1 ? (
            <div className="w-container p-0">
              <Col xs={12} className="my-4 text-center">
                <h2 className="text-center">Nenhum resultado</h2>
              </Col>
            </div>
          ) : (
            <>
              {!!courses.length && (
                <div className="w-container my-3">
                  <h3 className="ml-3 ml-md-0">Cursos</h3>
                  <Row>
                    {courses.map((course) => {
                      let progress = "";
                      if (course.course_stats.length === 0) {
                        progress = 0;
                      } else if (course.course_stats.length && course.course_stats[0].course_status === "CONCLUIDO") {
                        progress = 100;
                      } else if (course.course_stats.length && course.course_stats[0].progress) {
                        progress = Math.round(course.course_stats[0].progress);
                      }

                      return (
                        <Col key={course.id} xs={12} md={4} className="my-3 my-md-3">
                          <CourseCard
                            image={course.image !== null ? course.image.urlPublica : Placeholder}
                            duration={course.duration}
                            url={
                              course.course_stats.length &&
                              course.course_stats[0].course_status === "EM_ANDAMENTO" &&
                              course.course_stats[0].current_lesson_time !== null
                                ? `/lms/courses/${course.id}?hasresume=true`
                                : `/lms/courses/${course.id}`
                            }
                            tags={course.course_categories}
                            title={course.title}
                            progress={course.scorm ? progress : course.progress}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              )}

              {!!trails.length && (
                <div className="w-container my-3">
                  <h3 className="ml-3 ml-md-0">Trilhas</h3>
                  <Row>
                    {trails.map((trail) => (
                      <Col key={trail.id} xs={12} md={4} className="my-3 my-md-3">
                        <CourseCard
                          title={trail.title}
                          image={trail.image}
                          url={`/lms/trails/${trail.id}`}
                          tags={trail.category_courses}
                          progress={trail.progress}
                        />
                      </Col>
                    ))}
                  </Row>
                </div>
              )}
            </>
          )}
        </>
      )}
    </Base>
  );
};

export default Search;
