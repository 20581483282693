import api from '../../api';

const fetchMenu = (params) => api.get('/cms/menus', {
  params: {
    ...params,
  },
});

export {
  fetchMenu,
};