import React from 'react';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';


const Category = (props) => (
    <Col xs={4} className='my-3 p-1 p-md-3'>
        <Link to={props.url} className="category-card w-inline-block w-100">
            <i className={`text-block _1 ${props.icon}`}/>
            <h3>{props.title}</h3>
            <p className="paragraph-1 d-none d-md-flex" dangerouslySetInnerHTML={{__html: props.description}}/>
        </Link>
    </Col>
);

export default Category;
