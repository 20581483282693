import api from '../../../api';

const fetchClassroom = (params) => api.get('/lms/classroom_courses', {
  params: {
    ...params,
    active: true,
    'order[published_at]': 'desc'
  },
});

const fetchEnrollments = (params) => api.get('/lms/enrollments', {
  params: {
    ...params,
  },
});

const registrationClassroom = (body) => api.post('/lms/enrollments', body);

const removeRegistration = id => api.delete(`/lms/enrollments/${id}`);

export {
  fetchClassroom,
  registrationClassroom,
  fetchEnrollments,
  removeRegistration
};