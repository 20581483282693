import api from '../../api';

const fetchCursosAndamento = (params) => api.get('/lms/course_stats?course_status=EM_ANDAMENTO', {
    params: {
        ...params,
    },
    active: true
});

export {
    fetchCursosAndamento,
};