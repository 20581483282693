import api from '../../../api';

const fetchTrails = (params) => api.get('/lms/trails', {
  params: {
    ...params,
    [`order[created_at]`]: 'desc',
    active: true
  },
});

const fetchTrailsCount = (params) => api.get('/lms/trails/count_by')

const fetchTrailsInternal = (route, params) => api.get(`${route}`, {
  params: {
    ...params,
  },
});

const fetchTrailsSearch = (search, params) =>
  api.get(`/lms/trails?match[title]=${search}`, {
    params: {
      ...params,
      active: true,
    },
  });


export {
  fetchTrails,
  fetchTrailsInternal,
  fetchTrailsCount,
  fetchTrailsSearch
}; 
