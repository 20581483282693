import React, { useState, useEffect } from 'react';
import { Row, Col, Modal } from 'reactstrap';
import { CourseBox, MainDate, Andress, CourseDescription, BtnMatricula, BtnMatriculado, BtnEsgotato, BtnCancel } from './style';
import { convDate } from '../../../util/convDate';
import { registrationClassroom } from '../../../services/endpoints/lms/courses/presencial'
import moment from 'moment';
import { fetchEnrollments, removeRegistration } from '../../../services/endpoints/lms/courses/presencial';

const CourseLine = (props) => {

    const [ enrollId, setEnrollId ] = useState(null);
    const [ modal, setModal ] = useState(false);
    const [ alertModal, setAlertModal ] = useState('');
    const [ removeEnrollModal, setRemoveEnrollModal ] = useState(false);
    const [ CourseAvailable, setCourseAvailable ] = useState(false);
    const [ registered, setRegistered ] = useState(false);
    
    const toggle = () => setModal(!modal);
    const toggleRemove = () => setRemoveEnrollModal(!removeEnrollModal);

    const meses = ['Janeiro','Fevereiro','Março','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'];

    const day = moment(convDate(props.course.published_at)).format('DD');
    const month = meses[(moment(convDate(props.course.published_at)).format('M'))-1];
    const datefull = moment(convDate(props.course.published_at)).format('DD/MM/YYYY');
    const hour = moment(convDate(props.course.published_at)).format('HH');

    const date = new Date();
    const enrollment_control = props.course.enrollment_control;
    const enrollment_start = convDate(props.course.enrollment_start);
    const enrollment_end = convDate(props.course.enrollment_end);

    useEffect(() => {

        fetchEnrollments({
            user_iri: atob(localStorage.getItem(`user-endpoint`)),
            classroom_course_id: props.course.id
        })
        .then(res => {
            if(res.data.length > 0){
                setEnrollId(res.data[0].id);
                setRegistered(true);
            }
        })
        .catch((err) => {
            console.log('Erro ao buscar matriculas', err)
        })

        if(enrollment_control && enrollment_start <= date.getTime() && enrollment_end >= date.getTime()){
            setCourseAvailable(true)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[date, enrollment_control, enrollment_start, enrollment_end, registered])

    const registration = id =>{
        registrationClassroom(
            {
                "status": "Matriculado",
                "user_iri": atob(localStorage.getItem(`user-endpoint`)),
                "classroom_course": id,
                "enrolled_at": moment(date).format('YYYY-MM-DD HH:mm:ss'),
                "active": true
            }
        )
        .then((resp)=>{
            setAlertModal(`Você acaba de se matricular nesse curso!`);
            setModal(true);
        })
        .catch((err)=>{
            console.log('Erro ao realizar Matricula', err)
        })
    }

    const removeEnroll = id => {
        removeRegistration(id)
        .then(res => {
            setRemoveEnrollModal(false);
            setRegistered(false);   
        })
        .catch(err => {
            console.log('Erro ao cancelar Matricula', err)
        })
    }

    return (
        <>
            <CourseBox className={`row ${!CourseAvailable ? 'presencial-insdisponivel':''}`}>
                <Col xs={12} md={2}>
                    <MainDate>
                        <h2>{day}</h2>
                        <small>{month}</small>
                    </MainDate>
                </Col>
                <Col xs={12} md={4} className='my-2 my-md-0'>
                    <Andress>
                        <p><strong>Ínicio:</strong> {datefull} ás {hour}h</p>
                        {props.course.local === '' ? '' : <p><strong>Local:</strong> {props.course.local}</p>}
                        {props.course.address === '' ? '' : <p><strong>Endereço:</strong> {props.course.address}</p>}
                        {props.course.city === '' ? '' : <p><strong>Cidade:</strong> {props.course.city}</p>}
                    </Andress>
                    
                </Col>
                <Col xs={12} md={3} className='my-2 my-md-0'>
                    <CourseDescription>
                        <h3>{props.course.title}</h3>
                        <p dangerouslySetInnerHTML={{__html: props.course.summary}}/>
                    </CourseDescription>
                </Col>
                <Col xs={12} md={3} className='d-flex justify-content-center align-items-center'>
                    {CourseAvailable ?
                        <>
                            {registered ?
                                <BtnMatriculado onClick={toggleRemove}><span>Tenho interesse</span></BtnMatriculado>
                            :
                                <BtnMatricula onClick={e => registration(props.course.id)}>Matricular</BtnMatricula>
                            }
                        </>
                    :
                        <BtnEsgotato>Esgotado</BtnEsgotato>
                    }
                </Col>
            </CourseBox>
            <Modal isOpen={modal} toggle={toggle} className='modal-curso-presencial ' centered={true}>
                <i className="fas fa-times close-classroom-modal" onClick={toggle} />
                <p className='px-2 text-center'>{alertModal}</p>
            </Modal>
            
            <Modal isOpen={removeEnrollModal} toggle={toggleRemove} className='modal-curso-presencial ' centered={true}>
                <i className="fas fa-times close-classroom-modal" onClick={toggleRemove}/>
                <p className='px-2 text-center'>Tem certeza que deseja cancelar a matricula?</p>
                <Row className='pb-4 justify-content-around w-100 p-0 px-md-5'>
                    <BtnCancel className='m-0 ml-md-5 mb-2' onClick={e => removeEnroll(enrollId)}>Sim</BtnCancel>
                    <BtnEsgotato className='m-0 mr-md-5 pointer mb-2' onClick={toggleRemove}>Não</BtnEsgotato>
                </Row>
            </Modal>
        </>
    );
};

export default CourseLine;