import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import CourseInternal from './pages/Courses/Online/CourseShow';
// import CourseInternal from './pages/Courses/Online/NewcourseShow';
import ScormInternal from './pages/Courses/Online/ScormShow';
import CourseOnline from './pages/Courses/Online/';
import ProvaOnline from './pages/Courses/Online/ProvaOnline';
import CoursePresencial from './pages/Courses/Presencial/';
import Trilhas from './pages/Trilhas';
import TrilhasShow from './pages/Trilhas/TrilhasShow';
import Live from './pages/Live';
import Profile from './pages/Profile';
import Pages from './pages/Pages';
import Contact from './pages/Contato';
import Forum from './pages/Forum';
import Search from './pages/Search';
import Certificates from './pages/Certificates';
import Premios from './pages/Premios';
import Ranking from './pages/Ranking';

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/lms/courses" exact component={CourseOnline} />
      <Route path="/lms/courses/:id" exact component={CourseInternal} />
      <Route path="/lms/scorm/:id" exact component={ScormInternal} />
      <Route path="/lms/prova" exact component={ProvaOnline} />
      <Route path="/lms/classroom_courses" exact component={CoursePresencial} />
      <Route path="/lms/trails" exact component={Trilhas} />
      <Route path="/lms/trails/:id" exact component={TrilhasShow} />
      <Route path="/live" exact component={Live} />
      <Route path="/profile" exact component={Profile} />
      <Route path="/p/:slug" exact component={Pages} />
      <Route path="/contact" exact component={Contact} />
      <Route path="/forum" exact component={Forum} />
      <Route path="/search" exact component={Search} />
      <Route path="/lms/certificates" exact component={Certificates} />
      <Route path="/premios" exact component={Premios} />
      <Route path="/ranking" exact component={Ranking} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
