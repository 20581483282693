import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ProfileBox = styled.div`
    display: flex;
    padding: 25px 15px;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 14px;
    box-shadow: none;
    text-align: center;
    justify-content: space-around;
    height: 100%;
`;

export const ProfileImage = styled.img`
    border-radius: 100%;
    width: 130px;
    height: 130px;
    object-fit: cover;

`;

export const ProfileName = styled.h2`
    margin: 25px 0 0;
    font-size: 18px;
    font-weight: normal;
`;

export const SubInfo = styled.div`
    font-size: 10px;
`;

export const BoxInterna = styled.div`
    display: flex;
    min-height: 158px;
    padding: 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #d6d6d6;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: none;
    margin: 10px -10px 0

    small {
        margin-top: 10px;
        margin-bottom: 0px;
        font-size: 12px;
        font-weight: 400;
        text-decoration: none;
    }

    h3 {
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 0;
        font-size: 20px;
        font-weight: 700;
    }

    a {
        color: #333;
        font-size: 12px;
        text-decoration: underline;

        &:hover {
            color: #0f4dbc;
        }
    }
`;

export const ProfileInfoBox = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 16px;
    background-color: #fff;
    opacity: 1;
    padding: 5px;

    h3 {
        padding: 10px 0 0;
        color: #333;
        font-size: 28px;
        font-weight: 500;
        margin: 0;
        text-align: center
    }

    small {
        margin-top: 0px;
        margin-bottom: 0px;
        color: #616161;
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        min-height: 36px;
        display: flex;
        align-items: center;
    }
`;

export const ProgressBox = styled.div`
    overflow: auto;
    margin-bottom: 0px;
    padding: 24px 30px 14px;
    border-style: solid;
    border-width: 1px;
    border-color: #eee;
    border-radius: 16px;
    background-color: #fff;

    h3 {
        color: #333;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 0;
        line-height: 1
    }

    @media ( max-width :991px) {
        padding: 24px 10px 14px;

        h3 {
            font-size: 14px
        }
    }
`;

export const ProgressBar = styled.div`
    width: 100%;
    height: 6px;
    margin-right: 0px;
    margin-bottom: 0px;
    align-self: auto;
    border-radius: 100px;
    background-color: #dbdbdb;
`;

export const ProgressCourseBox =styled.div`
    overflow: hidden;
    transition: ease all 500ms;
    margin: 30px 0;
`;

export const Image = styled.img`
    width: 100%;
    max-height: 60px;
    border-radius: 6px;
    color: #333;
    object-fit: cover;
`;

export const CourseName = styled.h3`
    margin-left: 0px;
    color: #333;
    font-weight: 600;
    text-decoration: none;
    font-size: 14px;
`;

export const BtnAccess = styled(Link)`
    display: block;
    border-radius: 16px;
    background-color: transparent;
    color: #00985f;
    border: solid 1px #00985f;
    font-weight: 500;
    text-align: center;
    font-size: 14px;
    padding: 9px 10px;
    -webkit-text-decoration: none;
    text-decoration: none;
    -webkit-transition: ease all 500ms;
    transition: ease all 500ms;

    @media ( max-width :991px) {
        font-size: 11px;
        padding: 9px 0;
        border-radius: 10px;
    }

    &:hover {
        background-color: #00985f;
        transform: translate(0px,-5px);
        color: #fff;
        text-decoration: none;
    }
`;

export const ActivityMonth = styled.div`
    position: relative;
    height: 230px;
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    margin-bottom: 10px;
`;


export const MonthBar = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    div {
        height: ${props => props.height || "3"}%;
        width: 6px;
        border-radius: 5px;
        background-image: linear-gradient(180deg, #07c51a, #00985f);
        margin: 0 3px;
    }
`;

export const MonthText = styled.div`
    margin-top: 10px;
    color: #616161;
    font-size: 12px;
    text-transform: capitalize;
    text-align: center;

    @media ( max-width: 768px) {
        font-size: 10px;
    }
`;

export const Month = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
`;
