import api from '../../api';

const fetchRanking = (id, params) => api.get(`/gamification/ranking/user/${id}`, {
    params: {
        ...params,
    },
});

const fetchRankingList = (params) => api.get('/gamification/ranking');

export {
    fetchRanking,
    fetchRankingList,

};