import React, { useState, useLayoutEffect } from 'react';
import { Row } from 'reactstrap';
import { HeaderStyled } from '../../styles/base';
import MainMenu from './Menu';
import LogoMaisSaber from './LogoHeader';
import SearchButtom from './SearchButtom';

const Header = () => {

  const [scroll, setScroll] = useState(0);
  const [openMenu, setOpenMenu] = useState(false);

  function handleScroll() {
    setScroll(window.scrollY);
  }

  function setMenu(e) {
    setOpenMenu(e);
  }

  useLayoutEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      setScroll(0);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [])

  return (
    <HeaderStyled
      id='menutop'
      className={`py-2 ${scroll > 200 ? 'hidemenu' : ''}`}
    >
      <div className='w-container'>
        <Row className='flex-row flex-nowrap justify-content-between'>
          <LogoMaisSaber url='/' />
          <div className='d-flex d-lg-none'><SearchButtom /></div>
          <MainMenu
            state={openMenu}
            name={atob(window.localStorage.getItem('user-name'))}
          />
          <button className={`burger ${openMenu ? 'menu-open' : ''}`} onClick={e => { setMenu(!openMenu) }}>
            <i className="far fa-bars"></i>
          </button>
        </Row>
      </div>
    </HeaderStyled>
  );
};

export default Header;
