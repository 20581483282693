import styled from 'styled-components';

export const CertificateCard = styled.div`
    padding: 0px;
    border-radius: 16px;
    box-shadow: 0 2px 13px -8px #6d6b6b;
    transition: box-shadow 400ms ease, transform 400ms ease;
    overflow: hidden;

    &:hover {
        border: 1px solid transparent;
        box-shadow: 0 10px 20px 0 rgba(14, 14, 14, 0.15);
        transform: translate(0px, -6px) scale(1.02);
    }

`;

export const CertCardHead = styled.div`
    position: relative;
    height: 0;
    overflow: hidden;
    padding-bottom: 65%;

    &:after {
        content: '';
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.57), rgba(0, 0, 0, 0.57));
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
    }

    img {
        position: absolute;
        object-fit: cover;
        object-position: top;
        height: 100%;
        width: 100%;
    }

    i {
        font-size: 60px;
        font-weight: bold;
        position: absolute;
        color: #ffa100;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
    }

    small {
        position: absolute;
        bottom: 5px;
        right: 10px;
        color: #aca3a3;
        font-size: 14px;
        text-align: right;
        text-decoration: none;
        z-index: 1;
    }
`;

export const CertCardBody = styled.div`
    padding: 25px;
    position: relative;

    h2 {
        margin-top: 5px;
        margin-bottom: 15px;
        font-size: 20px;
        line-height: 1;
        font-weight: 700;
    }

    p {
        margin-bottom: 10px;
        color: #616161;
        font-size: 14px;
    }

    p:last-child {
        margin-bottom: 50px;
    }

    button {
        display: flex;
        margin-top: 20px;
        justify-content: flex-end;
        align-items: center;
        color: #f9461c;
        text-align: left;
        font-size: 14px;
        background: transparent;
        position: absolute;
        right: 25px;
        bottom: 25px;

        &:hover {
            color: #f9461c;
        }

        i {
            margin-left: 
            5px;
        }
    }
`;