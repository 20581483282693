import styled from 'styled-components';
// import { Link } from 'react-router-dom';


export const BtnFinish = styled.button`
    display: flex;
    border-radius: 16px;
    background-color: #00985f;
    transition: transform 500ms ease;
    color: #fff;
    font-weight: 500;
    text-align: center;
    font-size: 14px;
    padding: 10px 30px;
    margin-bottom: 100px;
    opacity: 1;

    &:disabled {
        cursor: no-drop;
        opacity: .5;
    }
`;

export const ProgresExam = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 0 20px 2px rgba(60, 60, 60, 0.15);

    h2 {
        margin: 0;
        font-size: 18px
    }
`;

export const Progress = styled.div`

    width: 100%;
    height: 10px;
    max-width: 720px;
    margin-left: 40px;
    border: 1px solid transparent;
    border-radius: 20px;
    background-image: linear-gradient(180deg, #dbdbdb, #dbdbdb);
    transition: ease all 200ms;

    div {
        background-image: linear-gradient(180deg, #07c51a, #00985f);
        width: ${props => props.progress}%;
        display: flex;
        height: 100%;
        overflow: hidden;
        border-radius: 20px;
        color: transparent;
        transition: ease all 200ms;
    }
`;

export const BtnCourseExam = styled.button`
    background: transparent;
    border: solid #fff 1px;
    color: #fff;
    border-radius: 16px;
    transition: ease all 200ms;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0;
    width:100%;

    &:hover {
        border-color: transparent;
        background-color: #ffa100;
        transform: translate(0px, -3px);
        color: #333;
        text-decoration: none;
    }
`;

export const LessonExamBox = styled.div`
    padding: 40px 31px;
    border-radius: 16px;
    background-color: #00985f;
    min-height: 200px; 
    margin: 0 -5px;   
    
    h2 {
        color: #fff;
        margin: 0;
    }

    p {
        color: #fff;
        font-size: 14px;
    }
`;

export const LessonExams = styled.div``;

export const LessonExam = styled.button`
    display: flex;
    margin: 1%;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 10px 20px 0 rgba(14,14,14,0.15);
    transition: transform 400ms ease,border 400ms ease;
    text-decoration: none;
    color: #000;
    width:100%;
    min-height: 65px;

    &:hover {
        border: 1px solid #ddd;
        box-shadow: none;
        transform: scale(1) translate(0px,6px);
        text-decoration: none;
        color: #000;
    }

    &.btn-aproved {
        background: #00985f !important;
        color: #fff !important;
        border: 1px solid #fff;
        
        &:hover {
            transform: scale(1) translate(0px,0px);
            box-shadow: 0 10px 20px 0 rgba(14,14,14,0.15);
        }
    }
`;