import React, { useState, useEffect } from 'react';
import { Col, Row, Spinner, Modal } from 'reactstrap';
import { PrimaryTitle } from '../../styles/base';
import Placeholder from '../../assets/images/placeholder.jpg'
import Base from '../../theme/Base';
import PremioCard from '../../components/Premios/PremioCard';
import { PointsBox, Points } from './style';
import { fetchRanking } from '../../services/endpoints/gamification/ranking';
import { fetchRewards } from '../../services/endpoints/gamification/rewards';

const Premios = (props) => {

  const [ points, setPoints ] = useState(0);
  const [ premios, setPremios ] = useState([]);
  const [ loading, setLoading ] = useState(true);
  const [ refresh, setRefresh ] = useState(false);
  const [ modal, setModal ] = useState(false)

  const toggle = () => setModal(!modal);

  const getPoints = async () => {
    try {
      const response = await fetchRanking(atob(window.localStorage.getItem('user-id')));
      const { data } = response;
      setPoints(data[0].amount);

    } catch (error) {
      console.error('Prêmios.getPoints', error);
    }
  }

  const getPremios = async () => {
    try {
      const response = await fetchRewards();
      const { data } = response;
      setPremios(data.filter(item => item.amount > 0));
      setLoading(false);

    } catch (error) {
      console.error('Prêmios.getPremios', error);
    }
  }

  useEffect(() => {
    setLoading(true);
    getPremios();
    getPoints();
  },[refresh])

  return (
    <Base>
      {loading ? 
        <Row className='m-0'>
          <Col xs={12} className='my-5 text-center'>
            <Spinner color="dark" />
          </Col>
        </Row>:
        <>
          <div className="w-container my-5">
            <PrimaryTitle>Loja de Prêmios</PrimaryTitle>
            <Row>
              <Col xs={12} lg={6} className='my-3 my-md-3'>
                <PointsBox>
                  <span>Pontos disponíveis para troca </span> <Points><i className="fal fa-trophy-alt"/> {points}</Points>
                </PointsBox>
              </Col>
            </Row>
            <Row>
              {premios.length === 0?(
                <Col xs={12} className='my-5 text-center'>
                  <h2 className="text-center">Nenhum prêmio disponível.</h2>
                </Col>
              ):(
                premios.map(premio => (
                  <Col key={premio.id} xs={12} md={6} lg={3} className='my-3 my-md-3'>
                    <PremioCard
                      id={premio.id}
                      image={premio.image ? premio.image.urlPublica : Placeholder} 
                      name={premio.title}
                      cost={premio.points}
                      amount={points}
                      status='available'
                      setRefresh={setRefresh}
                      setModal={setModal}
                    />
                  </Col>
                ))
              )}
            </Row>
          </div>
        </>
      }
      <Modal isOpen={modal} toggle={toggle} className='modal-curso-presencial ' centered={true}>
        <i className="fas fa-times close-classroom-modal" onClick={toggle} />
        <p className='px-2 text-center'>Troca bem sucedida.</p>
      </Modal>
    </Base>
  );
};

export default Premios;