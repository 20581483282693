import api from '../../api';

const fetchBannersByGroup = (id = '', params) => api.get(`/cms/banners?wherein[banner_group.id]=${id}`, {
    params: {
        ...params,
        ['wherein[banner_group.id']: id
    },
});

const fetchBannersGroup = (params) => api.get('/cms/banner_groups', {
    params: {
        ...params
    },
});

const fetchBanner = (id, params = {}) => api.get(`/cms/banners/${id}`, {
    params: {
        ...params,
    },
});

export {
    fetchBannersByGroup,
    fetchBannersGroup,
    fetchBanner
};