import Cookie from 'js-cookie';

function getApiUrl() {
  return process.env.REACT_APP_API_URL;
}

function getSigninUrl() {
  return process.env.REACT_APP_SIGNIN_URL;
}

function getPrefix() {
  return process.env.REACT_APP_PREFIX;
}

function getToken() {
  return Cookie.get(process.env.REACT_APP_TOKEN);
}

export {
  getToken,
  getApiUrl,
  getSigninUrl,
  getPrefix,
};
