import React, { useState, useEffect } from 'react';
import { MonthBar, MonthText, ActivityMonth, Month } from './style';
import { fetchUserAccess } from '../../services/endpoints/general/userAccess';


const ProfileActivity = (props) => {

    const [ highestActivity, setHighestActivity ] = useState(0);
    const [ labels, setLabels ] = useState([]);
    const [ series, setSeries ] = useState([]);

    useEffect(()=>{

        fetchUserAccess()
        .then(access => {
            let labels = [];
            let series = [];

            access.data.map(item => {
                labels.push(item.key);
                series.push(item.total);
                return null;
            })

            setLabels(labels);
            setSeries(series);
            setHighestActivity(Math.max(...series))
        })
        .catch((err) => {
            console.log('Erro ao estatísticas de acesso.', err);
        })
    },[])

    return (
        <>
            <h4 className='m-0 my-4 my-md-1'>Atividades</h4>
            <ActivityMonth>
                {series.map((item, index) => (
                    <Month key={index}>
                        <MonthBar height={highestActivity === 0 ? 0 :(item*100)/highestActivity}>
                            <div/>
                        </MonthBar>
                        <MonthText dangerouslySetInnerHTML={{__html:labels[index].toLowerCase().replace('/','<br/>')}}/>
                    </Month>
                ))}
            </ActivityMonth>
        </>
    );
};

export default ProfileActivity;