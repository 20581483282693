import api from '../../api';

const fetchEvaluation = (params) => api.get(`/general/currentUserEvaluations`, {
  params: {
    ...params,
  },
});

const postEvaluation = params => api.post(`/general/evaluations`, params);

export {
  fetchEvaluation,
  postEvaluation
};
