import React, { useEffect, useState } from "react";
import styled from "styled-components";
import XMLParser from "react-xml-parser";
import ScormApi from "../../../util/ScormApi";
import ScormApi2004 from "../../../util/ScormApi.2004";
import api from "../../../services/api";
import moment from "moment";

const Scorm = ({ course, refresh, setRefresh }) => {
  const [scormUrl, setScormUrl] = useState("");

  const initCourseStats = async () => {
    try {
      const response = await api.get(`/lms/course_stats?wherein[course.id]=${course.id}`);
      const { data } = response;

      if (data.length === 0) {
        try {
          await api.post(`/lms/course_stats`, {
            completion_at: null,
            course: {
              id: course.id,
            },
            course_status: "EM_ANDAMENTO",
            current_lesson: 0,
            current_lesson_time: 0,
            progress: 10,
            user_iri: atob(localStorage.getItem(`user-endpoint`)),
            watcheds_lessons: 0,
          });
        } catch (error) {
          console.error("Scorm.initCourseStats", error);
        }
      }
    } catch (error) {
      console.error("Scorm.initCourseStats", error);
    }
  };

  const finishCourseStats = async () => {
    try {
      const res = await api.get(`/lms/course_stats?wherein[course.id]=${course.id}`);
      const { data } = res;

      if (data.length) {
        try {
          const put = await api.put(`/lms/course_stats/${data[0].id}`, {
            course_status: "CONCLUIDO",
            progress: 100,
            completion_at: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
          });

          if (put.status === "200") {
            setRefresh(!refresh);
          }
        } catch (error) {
          console.error("Scorm.finishCourseStats", error);
        }
      }
    } catch (error) {
      console.error("SambaVideo.finishCourseStats", error);
    }
  };

  let initialized = false;
  let finished = false;

  const onChange = (event, value) => {
    const statusIndex = value && value["version"] === "2004" ? "cmi.completion_status" : "cmi.core.lesson_status";

    if (!initialized && value && value[statusIndex] === "incomplete") {
      initCourseStats();
      initialized = true;
    }

    if (!finished && value && value[statusIndex] === "completed") {
      finishCourseStats();
      finished = true;
    }
  };

  useEffect(() => {
    const temp = course.file_scorm.urlPublica.split("/");

    window.API = new ScormApi(onChange);
    window.API_1484_11 = new ScormApi2004(onChange);

    fetch(`${window.location.origin}/${process.env.REACT_APP_SCORM_FOLDER}/${temp[temp.length - 1].replace(".zip", "")}/imsmanifest.xml`)
      // fetch(`/scorm-oiaeo/imsmanifest.xml`)
      .then((response) => response.text())
      .then((text) => {
        const xml = new XMLParser().parseFromString(text);
        const identifierRef = xml.getElementsByTagName("item")[0].attributes.identifierref;

        const resources = xml.getElementsByTagName("resource");
        const rootResource = resources.find((resource) => resource.attributes.identifier === identifierRef);

        setScormUrl(
          `${window.location.origin}/${process.env.REACT_APP_SCORM_FOLDER}/${temp[temp.length - 1].replace(".zip", "")}/${rootResource.attributes.href}`
        );
        // setScormUrl(`/scorm-oiaeo/${rootResource.attributes.href}`)
      });
  }, []);

  if (!scormUrl) {
    return null;
  }

  return (
    <FrameBox>
      <iframe src={scormUrl} scrolling="no" frameBorder="0" allow="geolocation; microphone; camera; encrypted-media; midi"></iframe>
    </FrameBox>
  );
};

export default Scorm;

const FrameBox = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 50%;
  position: relative;

  iframe {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;
