import React, { useState } from 'react';
import Base from '../../theme/Base';
import { Modal } from 'reactstrap';
import Categories from '../../components/Home/Categories'
import MainBanner from '../../components/Home/MainBanner';
import { updateCurrentUser } from '../../services/endpoints/general/users';


const BlockedHome = (props) => {

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const handleChange = value => {
    setEmail(value.replace(/ /g, ''));
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    if (email && validateEmail(email)) {
      try {
        await updateCurrentUser(atob(window.localStorage.getItem('user-id')), {
          email: email
        });
        localStorage.setItem(`user-email`, btoa(email));
        props.history.go();
      } catch (error) {
        console.warn('BlockedHome.onSubmit', error);
        if (error.response.status === 400) {
          setMessage(error.response.data.detail.replace('email: ', ''));
        }
      }

    } else {
      setMessage('Você precisa digitar um email válido.')
    }
  }

  return (
    <Base>
      <Modal isOpen={true} className='modal-blockled-home' centered={true}>
        <p>Insira seu email para validar seu cadastro.</p>
        <span className='email-message'>{message}</span>
        <form>
          <input type="email" value={email} onChange={(e) => handleChange(e.target.value)} placeholder='E-mail' />
          <button type='submit' onClick={e => onSubmit(e)}>Salvar</button>
        </form>
      </Modal>

      <MainBanner />
      <Categories />
    </Base>
  );
};

export default BlockedHome;
