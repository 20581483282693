import React, { useEffect, useState } from "react";
import InternalPageBanner from "../../components/InternalPageBanner";
import { PrimaryTitle, LoadMoreBtn } from "../../styles/base";
import { Col, Row, Spinner } from "reactstrap";
import CourseCard from "../../components/Course/Card";
import Placeholder from "../../assets/images/placeholder.jpg";
import {
  fetchTrails,
  fetchTrailsCount,
} from "../../services/endpoints/lms/trails";
import { fetchBannersByGroup } from "../../services/endpoints/cms/banners";
import Base from "../../theme/Base";

const Trilhas = (props) => {
  const [trails, setTrails] = useState([]);
  const [banner, setBanner] = useState({});
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [loadingPage, setloadingPage] = useState(false);
  const [countPage, setCountPage] = useState(0);

  const perPage = 9;

  const order = (a, b) => {
    let comparison = 0;
    if (a.position > b.position) {
      comparison = 1;
    } else if (a.position < b.position) {
      comparison = -1;
    }
    return comparison;
  };

  const getImage = (item) => {
    if (item.thumbnail) {
      return item.thumbnail.urlPublica;
    }
    if (
      item.trails_courses.length &&
      item.trails_courses[0].hasOwnProperty("course") &&
      item.trails_courses[0].course &&
      item.trails_courses[0].course.hasOwnProperty("image") &&
      item.trails_courses[0].course.image !== null &&
      item.trails_courses[0].course.image.hasOwnProperty("urlPublica")
    ) {
      return item.trails_courses[0].course.image.urlPublica;
    }
    return Placeholder;
  };

  const getBanner = async () => {
    try {
      const response = await fetchBannersByGroup(2);
      const { data } = response;

      return data[0].active ? data[0] : {};
    } catch (error) {
      console.error("Trilhas.getBanner", error);
    }
  };

  const getTrailsCount = async () => {
    try {
      const response = await fetchTrailsCount();
      const { data } = response;

      if (data % perPage >= 1) {
        return Math.trunc(data / perPage) + 1;
      } else {
        return Math.trunc(data / perPage);
      }
    } catch (error) {
      console.error("Trilhas.getTrailsCount", error);
    }
  };

  const getQuantity = (arr, value) => {
    let quantity = 0;
    arr.forEach((item) => {
      if (item === value) {
        quantity++;
      }
    });

    return quantity;
  };

  const getTrails = async (pageNumber) => {
    try {
      const response = await fetchTrails({
        perPage: perPage,
        page: pageNumber,
      });
      const { data } = response;

      const trilhas = [];

      data.forEach((item) => {
        // let tempProgress = [];
        // item.trails_courses.forEach((item) => {
        //   if (
        //     item.course &&
        //     !!item.course.length &&
        //     !!item.course.course_stats.length
        //   ) {
        //     tempProgress.push(item.course.course_stats[0].progress);
        //   }
        // });

        // let trailProgress =
        //   (getQuantity(tempProgress, 100) * 100) / item.trails_courses.length;

        trilhas.push({
          id: item.id,
          image: getImage(item),
          title: item.title,
          category_courses:
            (item.trails_courses &&
              item.trails_courses[0] &&
              item.trails_courses[0].course &&
              item.trails_courses[0].course.course_categories) ||
            [],
          position: item.position,
          hasStats: item.trail_stats.length > 0,
          progress: Math.round(item.progress),
        });
      });

      return trilhas;
    } catch (error) {
      console.error("Recommended.getTrails", error);
      return null;
    }
  };

  const loadMore = async () => {
    setPage((prev) => prev + 1);
    setloadingPage(true);

    const responseTrails = await getTrails(page);
    setTrails(trails.concat((responseTrails || []).sort(order)));
    setloadingPage(false);
  };

  const loadContent = async () => {
    setCountPage(await getTrailsCount());
    setBanner(await getBanner());
    const responseTrails = await getTrails(0);
    setTrails((responseTrails || []).sort(order));
    setLoading(false);
  };

  useEffect(() => {
    loadContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Base>
      {loading ? (
        <Row className='m-0'>
          <Col xs={12} className='my-5 text-center'>
            <Spinner color='dark' />
          </Col>
        </Row>
      ) : (
        <>
          {banner && <InternalPageBanner className='mb-5' content={banner} />}
          <div className='w-container'>
            <PrimaryTitle>Trilhas</PrimaryTitle>
            <Row>
              {trails.map((trail) => {
                return (
                  <Col key={trail.id} xs={12} md={4} className='my-3 my-md-3'>
                    <CourseCard
                      image={trail.image !== null ? trail.image : Placeholder}
                      url={`/lms/trails/${trail.id}${
                        trail.hasStats ? `?hasresume=true` : ""
                      }`}
                      tags={(trail && trail.course_categories) || []}
                      title={trail.title}
                      progress={trail.progress}
                    />
                  </Col>
                );
              })}
            </Row>
            {page < countPage ? (
              loadingPage ? (
                <Row className='m-0'>
                  <Col xs={12} className='my-4 text-center'>
                    <Spinner color='dark' />:
                  </Col>
                </Row>
              ) : (
                <LoadMoreBtn onClick={(e) => loadMore()}>
                  Carregar Mais
                </LoadMoreBtn>
              )
            ) : (
              <div className='my-5' />
            )}
          </div>
        </>
      )}
    </Base>
  );
};

export default Trilhas;
