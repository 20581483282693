class ScormApi2004 {
  state = {};
  errorString = {};
  lastError = 0;
  listener;

  constructor(listener = () => { }) {
    this.listener = listener;
    this.state["cmi.student_id"] = Math.random();
    this.state["version"] = "2004";
    this.state["cmi.student_name"] = Math.random();
    this.state["cmi.lesson_mode"] = "123";
    this.state["cmi.location"] = "0";
    this.state["cmi.completion_status"] = "not attempted";
    this.state["cmi.credit"] = "123";
    this.state["cmi.score.raw"] = "123";
    this.state["cmi.score.max"] = "123";
    this.state["cmi.score.min"] = "123";
    // const confirmShow = false;

    let totalTime = 12;
    let totalTimeHour = totalTime / 144000;
    totalTime %= 144000;
    let totalTimeMinte = totalTime / 6000;
    totalTime %= 6000;
    let totalTimeSecond = totalTime / 100;
    totalTime %= 100;

    this.state["cmi.total_time"] = "" + totalTimeHour + ":" + totalTimeMinte + ":" + totalTimeSecond + "." + totalTime;
    this.state["cmi.entry"] = "123";
    this.state["cmi.suspend_data"] = "123";
    this.state["cmi.launch_data"] = "123";
    this.state["cmi._children"] = "student_id,student_name,lesson_location,credit,lesson_status,entry,score,total_time,lesson_mode,exit,session_time";
    this.state["cmi.score._children"] = "raw,min,max";
    this.state["cmi.session_time"] = "0000:00:00.00";
    this.state["cmi.exit"] = "";
    this.state["cmi.student_data._children"] = "mastery_score,time_limit_action,max_time_allowed";
    this.state["cmi.student_data.mastery_score"] = "123";
    this.state["cmi.student_data.max_time_allowed"] = "123";
    this.state["cmi.student_data.time_limit_action"] = "123";

    this.errorString["0"] = "No error";
    this.errorString["101"] = "General Exception";
    this.errorString["201"] = "Invalid Argument Error";
    this.errorString["202"] = "Element cannot have children";
    this.errorString["203"] = "Element not an array.  Cannot have count";
    this.errorString["301"] = "Not initialized";
    this.errorString["401"] = "Not implemented error";
    this.errorString["402"] = "Invalid set value, element is a keyword";
    this.errorString["403"] = "Element is read only";
    this.errorString["404"] = "Element is write only";
    this.errorString["405"] = "Incorrect Data Type";
  }

  Initialize(arg) {
    this.listener("Initialize");
    return true;
  }
  Terminate() {
    this.listener("Finished");
    return true;
  }
  GetValue(index) {
    this.listener("GetValue", this.state[index]);
    if (!this.state[index]) {
      console.error(`Indice não encontrado: ${index}`);
    }
    return this.state[index];
  }
  SetValue(argName, argValue) {
    this.state = { ...this.state, ...{ [argName]: argValue } };
    this.listener("SetValue", this.state);
    return argValue;
  }
  Commit() {
    this.listener("Commit");
    return true;
  }
  GetLastError() {
    return this.lastError;
  }
  GetDiagnostic(errorCode) {
    this.listener("Diagnostic", errorCode);
    return errorCode;
  }

  GetErrorString(errorCode) {
    console.error("ERROR IN THE SCORM API: ", errorCode);
    return errorCode;
  }
}

export default ScormApi2004;
