import React, { useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'reactstrap';
import Base from '../../theme/Base';
import ProfileMainCard from '../../components/Profile/ProfileMainCard';
import ProfileInfo from '../../components/Profile/ProfileInfo';
import ProfileActivity from '../../components/Profile/ProfileActivity';
import ProfileProgessCourse from '../../components/Profile/ProfileProgessCourse';
// import PremiosAdquiridos from '../../components/Premios/PremiosAdquiridos';
// import PremiosLoja from '../../components/Premios/PremiosLoja';
import { ProgressBox } from './style';
import { PrimaryTitle } from '../../styles/base';
import { fetchCurrentUser } from '../../services/endpoints/general/users';
import { fetchRanking } from '../../services/endpoints/gamification/ranking';
import { fetchCursosAndamento } from '../../services/endpoints/lms/cursos_em_andamento';
import { fetchCursosNaoIniciados } from '../../services/endpoints/lms/cursos_nao_iniciados';
import { fetchCursosConcluidos } from '../../services/endpoints/lms/cursos_conluidos';
import { fetchTotalCourses } from '../../services/endpoints/lms/courses/online';
import { fetchUserStatistics } from '../../services/endpoints/lms/user_statistics';

const Profile = (props) => {

  const [profile, setProfile] = useState({});
  const [statistics, setStatistics] = useState({});
  const [points, setPoints] = useState(0);
  const [rankig, setRanking] = useState(0);
  const [loading, setLoading] = useState(true);
  const [andCursos, setAndCursos] = useState([]);
  const [naoIniciados, setNaoIniciados] = useState([]);
  const [conCursos, setConCursos] = useState([]);
  const [totalCourses, setTotalCourses] = useState(0);
  // const [ refresh, setRefresh ] = useState(false);

  useEffect(() => {

    fetchCurrentUser()
      .then((profile) => {
        setProfile(profile.data || {});
      })
      .catch((err) => {
        console.log('Erro ao buscar perfil', err);
      })

    fetchRanking(
      atob(window.localStorage.getItem('user-id'))
    )
      .then((ranking) => {
        if (ranking.data.length > 0) {
          setPoints(ranking.data[0].amount || 0)
          setRanking(ranking.data[0].position || 0)
        }
      })
      .catch((err) => {
        console.log('Erro ao buscar pontos', err);
      })

    fetchUserStatistics()
      .then((statistics) => {
        setStatistics(statistics.data || {});
      })
      .catch((err) => {
        console.log('Erro ao buscar estatisticas do perfil', err);
      })

    fetchTotalCourses()
      .then((res) => {
        const data = res.data || [];
        setTotalCourses(data.length)
      })
      .catch((err) => {
        console.log('Erro ao buscar cursos', err);
      })

    fetchCursosAndamento()
      .then(andcursos => {
        setAndCursos(andcursos.data || []);
      })
      .catch(err => {
        console.log('Erro ao buscar cursos em andamento', err);
      })

    fetchCursosAndamento()
      .then(andcursos => {
        setAndCursos(andcursos.data || []);
      })
      .catch(err => {
        console.log('Erro ao buscar cursos em andamento', err);
      })

    fetchCursosNaoIniciados()
      .then(res => {
        setNaoIniciados(res.data || []);
      })
      .catch(err => {
        console.log('Erro ao buscar cursos em andamento', err);
      })

    fetchCursosConcluidos()
      .then(concursos => {
        setConCursos(concursos.data || []);
        setLoading(false);
      })
      .catch(err => {
        console.log('Erro ao buscar cursos concluiidos', err);
      })
  }, []);

  return (
    <Base>
      {loading === true ?
        <Row className='m-0'>
          <Col xs={12} className='my-5 text-center'>
            <Spinner color="dark" />
          </Col>
        </Row> :
        <>
          <div className="w-container my-5">
            <PrimaryTitle className='mb-5'>Perfil</PrimaryTitle>
            <Row>
              <Col md={4} xs={12}>
                <ProfileMainCard
                  name={profile.name}
                  user={profile}
                  points={points}
                  classification={rankig}
                />
              </Col>
              <Col md={8} xs={12} className='px-0 mt-4 mt-md-0'>
                <Col xs={12} className='d-flex flex-column justify-content-between h-100'>
                  {statistics.hasOwnProperty('certificates') &&
                    <ProfileInfo
                      provas={statistics.exams}
                      videos={statistics.videos}
                      certificados={statistics.certificates}
                    />
                  }
                  <ProfileActivity />
                </Col>
              </Col>
            </Row>
          </div>
          <div className="andamento">
            <h2 className="text-block-34">Andamento dos Meus Cursos</h2>
            <div className="w-container">
              <Row>
                <Col xs={12}>
                  <ProgressBox>
                    <ProfileProgessCourse
                      title='Em andamento'
                      cursos={andCursos}
                      total={totalCourses}
                    />
                    <ProfileProgessCourse
                      title='Não iniciados'
                      cursos={naoIniciados}
                      total={totalCourses}
                    />
                    <ProfileProgessCourse
                      title='Concluidos'
                      cursos={conCursos}
                      total={totalCourses}
                    />
                  </ProgressBox>
                </Col>
              </Row>
            </div>
          </div>
          {/* <PremiosAdquiridos
            refresh={refresh}
          />
          <PremiosLoja
            setRefresh={setRefresh}
            amount={points}
          /> */}
        </>}
    </Base>
  );
};

export default Profile;
