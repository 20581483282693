import React from 'react';
import { Item } from './style';
import { Col, Row } from 'reactstrap';
import Avatar from '../../assets/images/user_placeholder.png';

const RankItem = (props) => {

    let position = '';

    switch (props.position) {
        case 1:
            position = 'rank-first';
            break;
        case 2:
            position = 'rank-second';
            break;
        case 3:
            position = 'rank-third';
            break;
        default:
            break;
    }

    return (
        <Item className={`${position} ${props.authenticated && 'authenticated-border'} `}>
            <Row className='w-100'>
                <Col xs={1} className={`rank-position`}>{props.position}º</Col>
                <Col xs={3} md={1}><img src={props.avatar || Avatar} alt="" /></Col>
                <Col xs={5} md={7} className={`rank-name ${props.authenticated ? 'authenticated' : ''}`}>{props.name}</Col>
                <Col xs={2} className='rank-score'>{props.score} pts</Col>
                <Col xs={1} className={`rank-medal`}><i className="fal fa-medal" /></Col>
            </Row>
        </Item>
    );
}

export default RankItem;