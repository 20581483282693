/** @format */

import React, { useState, useEffect } from "react";
import { Col, Row, Spinner, Modal } from "reactstrap";
import Base from "../../theme/Base";
import { BtnEsgotato, BtnMatricula } from "../../components/Course/Line/style";
import CourseSideBar from "../../components/Course/SideBar";
import CouseInfo from "../../components/Course/Info";
import CouseMaterial from "../../components/Course/Material";
import AulasProgress from "../../components/Course/AulasProgress";
import placeholder from "../../assets/images/placeholder.jpg";
import ContentMedia from "../../components/ContentMedia";
import { fetchTrailsInternal } from "../../services/endpoints/lms/trails";
import HandleViewContent from "../../components/ContentMedia/handleViewContent";
import { fetchCoursesInternal } from "../../services/endpoints/lms/courses/online";
import { fetchPerformedExams } from "../../services/endpoints/lms/provas";
import { fetchCursosConcluidos } from "../../services/endpoints/lms/cursos_conluidos";
import {
  BtnCourseExam,
  LessonExamBox,
  LessonExam,
} from "../Courses/Online/style";
import Slider from "react-slick";
// import { set } from "js-cookie";

const GetAprovedExames = ({ id, keytype, functionExam, lesson_exam }) => {
  const [note, setNote] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchPerformedExams({
          user_iri: atob(localStorage.getItem("user-endpoint")),
        });

        if (response && response.data) {
          response.data.map((item) => {
            if (item.exam.id === id && item.approved) {
              if (item[keytype] !== null) {
                setNote(item.grade_obtained);
              }
            }
            return item;
          });
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [id, keytype]);

  if (keytype === "lesson") {
    return (
      <Col xs={12} md={3}>
        <LessonExam
          disabled={note}
          className={`d-flex flex-column ${note ? "btn-aproved" : ""}`}
          onClick={(e) => (!note ? functionExam() : null)}
        >
          {lesson_exam.exam.title}
          {note && (
            <>
              <small>Aprovado</small>
              <small>
                <b>{Math.round(note)}%</b>
              </small>
            </>
          )}
        </LessonExam>
      </Col>
    );
  } else if (keytype === "course") {
    return (
      <BtnCourseExam
        disabled={note}
        className='d-flex flex-column'
        onClick={(e) => (!note ? functionExam() : null)}
      >
        {!note ? (
          <>Fazer Prova</>
        ) : (
          <>
            Aprovado
            <small>
              <b>{Math.round(note)}%</b>
            </small>
          </>
        )}
      </BtnCourseExam>
    );
  }
};

const TrailShow = (props) => {
  const [course, setCourse] = useState({});
  const [lessons, setLessons] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [loading, setLoading] = useState(true);
  const [trail, setTrail] = useState([]);
  const [trailMeta, setTrailMeta] = useState({});
  const [courseFinish, setCourseFinish] = useState(false);
  const [lessonStats, setLessonStats] = useState([]);
  // const [courseCompleted, setCourseCompleted] = useState(null);
  const [lessonsCompleted, setLessonsCompleted] = useState(null);
  const [examAvaliable, setExamAvaliable] = useState(false);
  const [arrowLesson, setArrowLesson] = useState(false);
  const [atualAula, setAtualAula] = useState(0);
  const [videoResume, setVideoResume] = useState(0);
  const [actualCourse, setActualCourse] = useState(0);
  const [modal, setModal] = useState(props.location.search ? true : false);

  const toggle = () => setModal(!modal);

  const getReferences = (reference, trailMeta) => {
    if (reference === "course") {
      let aula = trailMeta.stats.current_lesson_course;
      let curso = trailMeta.stats.current_course;

      if (
        course &&
        course.hasOwnProperty("lessons") &&
        aula + 1 > course.lessons.length
      ) {
        return curso + 1;
      }
      return curso;
    } else if (reference === "lesson") {
      if (
        course &&
        course.hasOwnProperty("lessons") &&
        course.lessons[trailMeta.stats.current_lesson_course] !== undefined
      ) {
        return trailMeta.stats.current_lesson_course;
      }
      return 0;
    } else if (reference === "resume") {
      return trailMeta.stats.current_lesson_course_time;
    }
    return 0;
  };

  const restaurar = () => {

    if (!!trail[getReferences("course", trailMeta)]) {
      setActualCourse(getReferences("course", trailMeta));
    } else {
      setActualCourse(0);
    }

    if (!!trail[getReferences("course", trailMeta)] && !!trail[getReferences("course", trailMeta)].course.lessons[getReferences("lesson", trailMeta)]) {
      setAtualAula(getReferences("lesson", trailMeta));
    } else {
      setAtualAula(0);
    }

    setVideoResume(getReferences("resume", trailMeta));
    document.body.classList.remove("modal-open");
    toggle();
  };

  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <button
        id='next-question'
        onClick={onClick}
        className={`${className}`}
      ></button>
    );
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <button
        id='prev-question'
        onClick={onClick}
        className={` ${className}`}
      ></button>
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const setExamInfo = (exam_id, object_id, redirect) => {
    localStorage.setItem(
      "exam_info",
      JSON.stringify({
        exam_id: exam_id,
        object_id: object_id,
        redirect: redirect,
      })
    );
    window.location.href = "/lms/prova";
  };

  function order(a, b) {
    let comparison = 0;
    if (a.position > b.position) {
      comparison = 1;
    } else if (a.position < b.position) {
      comparison = -1;
    }
    return comparison;
  }

  const verifyStats = (id, index) => {
    let course_id = id !== undefined ? id : course.id;

    fetchCoursesInternal(`/lms/courses/${course_id}`)
      .then((course) => {
        setCourse(course.data);

        let temp = [];
        course.data.lessons.map((i) =>
          temp.push(
            i.lesson_stats.filter(
              (lesson_stat) =>
                lesson_stat.user_iri ===
                atob(localStorage.getItem("user-endpoint"))
            )[0] || {}
          )
        );

        if (index !== undefined) {
          if (index === 0) {
            setAtualAula(0);
            setExamAvaliable(false);
          } else if (
            index === 0 ||
            (lessonStats[index - 1] !== undefined &&
              lessonStats[index - 1].hasOwnProperty("lesson_status") &&
              lessonStats[index - 1].lesson_status === "CONCLUIDO")
          ) {
            setAtualAula(index);
            setExamAvaliable(false);
          }
        }

        setLessonStats(temp);

        if (
          course.data.lessons.length > 0 &&
          course.data.lessons[atualAula].lesson_stats.filter(
            (item) =>
              item.user_iri === atob(localStorage.getItem("user-endpoint"))
          ).length > 0 &&
          course.data.lessons[atualAula].lesson_stats.filter(
            (item) =>
              item.user_iri === atob(localStorage.getItem("user-endpoint"))
          )[0].lesson_status === "CONCLUIDO"
        ) {
          setExamAvaliable(true);
        } else {
          setExamAvaliable(false);
        }

        let stats_temp = course.data.course_stats.filter(
          (course_stat) =>
            course_stat.user_iri === atob(localStorage.getItem("user-endpoint"))
        );
        if (
          stats_temp.length > 0 &&
          stats_temp[0].course_status === "CONCLUIDO"
        ) {
          setCourseFinish(true);
        } else {
          setCourseFinish(false);
        }
      })
      .catch((err) => {
        console.error("Erro ao verificar andamento do curso", err);
      });
  };

  // const verifyComplete = (id) => {
  //   if (
  //     courseCompleted !== null &&
  //     courseCompleted.filter((item) => item.id === id).length > 0
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const verifyLessonComplete = (stats) => {
    // if (
    //   lessonsCompleted !== null &&
    //   lessonsCompleted.filter((item) => item === id).length > 0
    // ) {
    //   return true;
    // } else {
    //   return false;
    // }

    return stats.length &&
      stats[0].progress === 100 &&
      (
        !stats[0].mandatory_approval ||
        (stats[0].mandatory_approval && stats[0].user_approved)
      )
  };

  useEffect(() => {
    const fetchCompletedCourses = () => {
      let course_completeds = [];

      fetchCursosConcluidos({
        user_iri: atob(localStorage.getItem("user-endpoint")),
      }).then((concluidos) => {
        if (!!concluidos.data) {
          concluidos.data.map((item) => {
            return item.course.course_exams.length === 0 ||
              (item.course.course_exams.length > 0 &&
                !item.course.course_exams[0].exam.mandatory_approval)
              ? course_completeds.push({
                id: item.course.id,
                course: item.course,
                applied_at: item.completion_at,
                tentatives: 1,
                highest_grade: "Não disponível",
              })
              : null;
          });
        }
      });

      fetchPerformedExams({
        user_iri: atob(localStorage.getItem("user-endpoint")),
      })
        .then((res) => {
          let temp = [];
          let diferentes = [];

          if (!!res.data) {
            res.data.map((i) => {
              return i.approved && i.course !== null && i.lesson === null
                ? temp.push({
                  id: i.id,
                  course: i.course,
                  grade_obtained: i.grade_obtained,
                  applied_at: i.applied_at,
                })
                : null;
            });

            const map = new Map();
            for (const item of temp.sort(order)) {
              if (!map.has(item.course.id)) {
                map.set(item.course.id, true);
                diferentes.push(item);
              }
            }

            diferentes.map((item) => {
              let tentatives = temp.filter((i) => i.course.id === item.course.id)
                .length;
              return course_completeds.push({
                id: item.course.id,
                course: item.course,
                applied_at: item.applied_at,
                tentatives: tentatives,
                highest_grade: item.grade_obtained,
              });
            });
          }

          // setCourseCompleted(course_completeds);
        })
        .catch((err) => {
          console.error("Erro ao buscar provas", err);
        });
    };

    fetchCompletedCourses();

    fetchTrailsInternal(window.location.pathname)
      .then((response) => {
        const trailInternal = ({
          ...response.data,
          trails_courses: response.data.trails_courses.filter((item) => item.course),
        });

        if (trailInternal.trails_courses.length > 0) {
          let courses_trail = [];

          trailInternal.trails_courses.map((item) => {
            return item.course && item.course.active
              ? courses_trail.push(item)
              : null;
          });

          setTrail(courses_trail);

          setTrailMeta({
            title: trailInternal.title,
            id: trailInternal.id,
            stats: trailInternal.trail_stats.length
              ? trailInternal.trail_stats[0]
              : {},
            force_sequence: trailInternal.force_courses_sequence,
          });
        }

        let courses =
          trailInternal.trails_courses[actualCourse] !== undefined
            ? trailInternal.trails_courses[actualCourse].course
            : trailInternal.trails_courses[0] ? trailInternal.trails_courses[0].course : null;

        setCourse(courses);

        if (
          courses &&
          courses.lessons.length > 0 &&
          courses.lessons[atualAula].lesson_stats.filter(
            (item) =>
              item.user_iri === atob(localStorage.getItem("user-endpoint"))
          ).length > 0 &&
          courses.lessons[atualAula].lesson_stats.filter(
            (item) =>
              item.user_iri === atob(localStorage.getItem("user-endpoint"))
          )[0].lesson_status === "CONCLUIDO"
        ) {
          setExamAvaliable(true);
        } else {
          setExamAvaliable(false);
        }
        if (courses) verifyStats(courses.id, atualAula);

        setLoading(false);
      })
      .catch((err) => {
        console.error("Erro ao buscar curso interno", err);
      });

    return () => {
      setTrail([]);
      setTrailMeta([]);
      setCourse({});
      setExamAvaliable(false);
      setLoading(true);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualCourse, atualAula]);

  useEffect(() => {
    let temp = [];
    let lessons_completeds = [];

    if (course && course.hasOwnProperty("lessons") && course.lessons.length > 0) {
      fetchPerformedExams({
        user_iri: atob(localStorage.getItem("user-endpoint")),
      })
        .then((res) => {
          const getExamAproval = (id) => {
            return (
              res.data.filter((item) => item.exam.id === id && item.approved)
                .length > 0
            );
          };

          course.lessons.sort(order).map((i) => {

            temp.push(
              i.lesson_stats.filter((lesson_stat) => lesson_stat.user_iri === atob(localStorage.getItem("user-endpoint"))).length > 0
                ? i.lesson_stats.filter(
                  (lesson_stat) =>
                    lesson_stat.user_iri ===
                    atob(localStorage.getItem("user-endpoint"))
                )[0]
                : {}
            );

            if (
              i.lesson_stats.filter(
                (item) =>
                  item.user_iri === atob(localStorage.getItem("user-endpoint"))
              ).length > 0 &&
              i.lesson_stats.filter(
                (item) =>
                  item.user_iri === atob(localStorage.getItem("user-endpoint"))
              )[0].lesson_status === "CONCLUIDO" &&
              i.lesson_exams.length && !i.lesson_exams.exam
            ) {
              lessons_completeds.push(i.id);
            } else if (
              i.lesson_stats.filter(
                (item) =>
                  item.user_iri === atob(localStorage.getItem("user-endpoint"))
              ).length > 0 &&
              i.lesson_stats.filter(
                (item) =>
                  item.user_iri === atob(localStorage.getItem("user-endpoint"))
              )[0].lesson_status === "CONCLUIDO" &&
              i.lesson_exams.length && i.lesson_exams.exam !== null
            ) {
              let count_exam = 0;

              i.lesson_exams.map((lesson_exam) => {
                return (!!lesson_exam.exam && !lesson_exam.exam.mandatory_approval) ||
                  getExamAproval(!!lesson_exam.exam && lesson_exam.exam.id)
                  ? count_exam++
                  : null;
              });

              return i.lesson_exams.length === count_exam
                ? lessons_completeds.push(i.id)
                : null;
            }
          });

          setLessonsCompleted(lessons_completeds);
        })
        .catch((err) => {
          console.error("Erro ao buscar provas de aulas", err);
        });

      setLessonStats(temp);
      setLessons(course.lessons[atualAula] || []);
      setMaterials(course.lessons[atualAula].lesson_support_materials || []);
    }
  }, [atualAula, course, actualCourse]);

  const verifyNextLesson = async () => {
    try {
      if(course.lessons.length - 1 > atualAula){
        setAtualAula((atualAula) => atualAula + 1)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (course.lessons) {
      if (course.lessons[course.lessons.length - 1].id === lessons.id) {
        setArrowLesson(false);
      } else {
        setArrowLesson(verifyLessonComplete(lessons.lesson_stats));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessons, lessonsCompleted]);

  const trailStats = {
    trailId: trailMeta.id,
    currenCourse: actualCourse,
    currenteLesson: atualAula,
  };

  return (
    <Base>
      {loading === true ? (
        <Row>
          <Col xs={12} className='my-5 text-center'>
            <Spinner color='dark' />
          </Col>
        </Row>
      ) : (
        <>
          {course == null || (course.lessons.length < 1 && atualAula) ? (
            <div className='w-container'>
              <Col xs={12} className='my-5 text-center'>
                <h2 className='text-center'>
                  Ainda não existem cursos nessa trilha
                </h2>
              </Col>
            </div>
          ) : (
            <>
              <Modal
                isOpen={modal}
                toggle={toggle}
                className='modal-curso-presencial '
                centered={true}
              >
                <i
                  className='fas fa-times close-classroom-modal'
                  onClick={toggle}
                />
                <p className='px-2 text-center'>Continuar de onde parou?</p>
                <Row className='pb-4 justify-content-around w-100 p-0 px-md-5'>
                  <BtnMatricula
                    className='m-0 ml-md-5 mb-2'
                    onClick={(e) => restaurar()}
                  >
                    Sim
                  </BtnMatricula>
                  <BtnEsgotato
                    className='m-0 mr-md-5 pointer mb-2'
                    onClick={toggle}
                  >
                    Não
                  </BtnEsgotato>
                </Row>
              </Modal>

              <div className='w-container'>
                {course && course.hasOwnProperty("id") && (
                  <Row className='my-5'>
                    <Col xs={12} md={9}>
                      <HandleViewContent.Provider value={{ verifyStats }}>
                        {!!course.lessons.length &&
                          course.lessons[atualAula].hasOwnProperty(
                            "lesson_contents"
                          ) &&
                          course.lessons[atualAula].lesson_contents.length >
                          0 && (
                            <>
                              {arrowLesson && (
                                <i
                                  onClick={(e) =>
                                    setAtualAula((atualAula) => atualAula + 1)
                                  }
                                  className='fas fa-chevron-right next-lesson-arrow'
                                />
                              )}
                              <ContentMedia
                                video={
                                  course.lessons[atualAula].lesson_contents[0]
                                }
                                trailStats={trailStats}
                                videoResume={videoResume}
                                last={course.lessons.length - 1 === atualAula}
                                hasExam={
                                  !!course.course_exams.length ||
                                  !!course.lessons[atualAula].lesson_exams
                                    .length
                                }
                                watched={
                                  course &&
                                    course.hasOwnProperty("lessons") &&
                                    course.lessons.length &&
                                    course.lessons[atualAula].lesson_stats
                                      .length &&
                                    course.lessons[
                                      atualAula
                                    ].lesson_stats[0].hasOwnProperty(
                                      "lesson_status"
                                    )
                                    ? course.lessons[atualAula].lesson_stats[0]
                                      .lesson_status === "CONCLUIDO"
                                    : false
                                }
                                thumbnail={
                                  course.lessons[atualAula].lesson_contents[0]
                                    .thumbnail
                                    ? course.lessons[atualAula]
                                      .lesson_contents[0].thumbnail.urlPublica
                                    : placeholder
                                }
                                autoNextLesson={verifyNextLesson}
                              />
                            </>
                          )}
                      </HandleViewContent.Provider>
                    </Col>
                    <Col xs={12} md={3}>
                      <CourseSideBar
                        aulas={course.lessons.length}
                        course_id={course.id}
                      />
                    </Col>
                  </Row>
                )}
              </div>

              <div className='w-container'>
                <Row>
                  <Col xs={12} md={9}>
                    <CouseInfo
                      title={lessons.title}
                      description={lessons.description}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    {materials.length > 0 && (
                      <CouseMaterial material={materials} />
                    )}
                  </Col>
                </Row>
              </div>

              {examAvaliable &&
                course.lessons[atualAula].lesson_exams.length > 0 &&
                course.lessons[atualAula].lesson_exams[0].exam !== null && (
                  <div className='w-container p-0 mt-5'>
                    <Row>
                      <Col xs={12}>
                        <LessonExamBox>
                          <h2>Atividades</h2>
                          <Row>
                            {course.lessons[atualAula].lesson_exams.map(
                              (lesson_exam) => {
                                if (lesson_exam.exam) {
                                  return (
                                    <GetAprovedExames
                                      key={lesson_exam.exam.id}
                                      lesson_exam={lesson_exam}
                                      id={lesson_exam.exam.id}
                                      keytype='lesson'
                                      functionExam={() =>
                                        setExamInfo(
                                          lesson_exam.exam.id,
                                          `lesson_id=${course.lessons[atualAula].id}`,
                                          window.location.pathname
                                        )
                                      }
                                    />
                                  );
                                }
                                return null;
                              }
                            )}
                          </Row>
                        </LessonExamBox>
                      </Col>
                    </Row>
                  </div>
                )}

              <div className='w-container p-0'>
                <Row className='my-5'>
                  <Col xs={12}>
                    <h2>{course.title}</h2>
                    <Slider {...settings} className='slider-course'>
                      {course.lessons.sort(order).map((lesson, i) => {
                        return (
                          <div
                            key={lesson.id}
                            className='course-slide-item'
                            xs={12}
                            md={3}
                            onClick={
                              i === 0 ||
                                !course.force_lessons_sequence ||
                                (course.lessons.sort(order)[i - 1] !== undefined &&
                                  verifyLessonComplete(
                                    course.lessons.sort(order)[i - 1].lesson_stats
                                  ))
                                ? (e) => {
                                  verifyStats(undefined, i);
                                  setVideoResume(0);
                                  setAtualAula(i);
                                }
                                : null
                            }
                          >
                            <AulasProgress
                              image={
                                lesson.lesson_contents.length > 0 &&
                                  lesson.lesson_contents[0].thumbnail !== null
                                  ? lesson.lesson_contents[0].thumbnail
                                    .urlPublica
                                  : placeholder
                              }
                              title={lesson.title}
                              watched={verifyLessonComplete(lesson.lesson_stats)}
                              unlocked={
                                i === 0 ||
                                !course.force_lessons_sequence ||
                                (course.lessons.sort(order)[i - 1] !==
                                  undefined &&
                                  verifyLessonComplete(course.lessons.sort(order)[i - 1].lesson_stats))
                              }
                            />
                          </div>
                        );
                      })}
                    </Slider>
                  </Col>
                </Row>
              </div>

              {course.course_exams.length > 0 &&
                course.course_exams[0].exam &&
                courseFinish && (
                  <div className='exam-area'>
                    <div className='w-container p-0'>
                      <Row className='d-flex justify-content-center align-items-center'>
                        <Col xs={10}>
                          <Row className='d-flex justify-content-center align-items-center'>
                            <Col xs={12} md={9}>
                              <div className='course-exame'>
                                <h2>PARABÉNS!</h2>
                                <p>
                                  Você concluiu 100% de todas as aulas neste
                                  curso. Para finalizar realize a prova.
                                </p>
                              </div>
                            </Col>
                            <Col xs={12} md={3}>
                              <GetAprovedExames
                                id={course.course_exams[0].exam.id}
                                keytype='course'
                                functionExam={() =>
                                  setExamInfo(
                                    course.course_exams[0].exam.id,
                                    `course_id=${course.id}`,
                                    window.location.pathname
                                  )
                                }
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}

              <div className='w-container'>
                {trail.length > 0 && (
                  <Row className='my-5'>
                    <Col xs={12}>
                      <h2>TRILHA - {trailMeta.title} - trilha</h2>
                      <Slider {...settings} className='slider-course'>
                        {trail.map((course, i) => {
                          return (
                            <div
                              className='course-slide-item'
                              onClick={
                                i === 0 ||
                                  !trailMeta.force_sequence ||
                                  (
                                    trail[i - 1] !== undefined &&
                                    trail[i - 1].course.course_stats.length &&
                                    trail[i - 1].course.course_stats[0].progress === 100 &&
                                    trail[i - 1].course.course_stats[0].user_approved === true
                                  )
                                  ? (e) => {
                                    setActualCourse(i);
                                    setAtualAula(0);
                                  }
                                  : null
                              }
                              key={course.id}
                              xs={12}
                              md={3}
                            >
                              <AulasProgress
                                course={course.course}
                                image={
                                  course.course.image !== null &&
                                    course.course.image !== null
                                    ? course.course.image.urlPublica
                                    : placeholder
                                }
                                title={course.course.title}
                                watched={
                                  course.course.progress === 100 &&
                                  !!course.course.course_stats.length &&
                                  course.course.course_stats[0].user_approved === true
                                }
                                unlocked={
                                  i === 0 ||
                                  !trailMeta.force_sequence ||
                                  (
                                    trail[i - 1] !== undefined &&
                                    trail[i - 1].course.course_stats.length &&
                                    trail[i - 1].course.course_stats[0].progress === 100 &&
                                    trail[i - 1].course.course_stats[0].user_approved === true
                                  )
                                }
                              />
                            </div>
                          );
                        })}
                      </Slider>
                    </Col>
                  </Row>
                )}
              </div>
            </>
          )}
        </>
      )}
    </Base>
  );
};

export default TrailShow;
