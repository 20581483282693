import styled, { keyframes } from 'styled-components';
import { slideInUp } from 'react-animations';


export const Slide = styled.div`
    position: relative;

    &.mask-text:after {
      content: '';
      background: linear-gradient(260deg, rgba(0, 0, 255, 0), rgba(29, 28, 28, 0.6) 77%);
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0
    }
`;

export const SlideImgContent = styled.div`
  height: 0;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-bottom: 46%;

  @media ( max-width: 992px) {
    padding-bottom: 75%;
  }
`;

export const SlideImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    top: 0;
    left: 0;
    position: absolute;

    @media ( max-width: 992px) {
      display: none!important;
    }
`;

export const SlideImageMobile = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    top: 0;
    left: 0;
    position: absolute;
    display: none!important;

    @media ( max-width: 992px) {
      display: block!important;
    }
`;

export const SlideInfo = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 940px;
    width: 80%;
    padding: 10px;
    transform: translate(-50%, -50%);
    z-index: 1;

    h1 {
        color: #fff;
        font-size: 48px;
        max-width: 600px
        margin-top: 0;
        margin-bottom: 0px;
        line-height: 60px;
    }

    p {
        margin-top: 20px;
        margin-bottom: 30px;
        color: #fff;
        max-width: 600px
        margin-top: 0px;
        margin-bottom: 0px;
    }

    @media ( max-width: 992px) {
      h1 {
        font-size: 40px;
        line-height: 50px;
        text-shadow: 1px 1px 3px #000;
      }

      p {
          font-size: 14px
          text-shadow: 1px 1px 3px #000;
      }
    }

    @media ( max-width: 768px) {
      h1 {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 15px;
        text-shadow: 1px 1px 3px #000;
      }

      p {
          font-size: 12px
      }
    }
`;

export const SlideBtn = styled.a`
  display: block;
  border-radius: 16px;
  background-color: transparent;
  color: #ffa100;
  border: solid 1px #ffa100;
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  width: fit-content;
  padding: 9px 38px;
  margin: 20px 0;
  text-decoration: none;
  transition: ease all 500ms;
  
  @media ( max-width: 992px) {
    margin: 10px 0;
  }

  &:hover {
    background-color: #ffa100;
    transform: translate(0px, -5px);
    color: #000;
    text-decoration: none;
  }
`;

export const NextArrow = styled.button`
    right: 0!important;
     position: absolute;
    z-index:999;
`;

export const PrevArrow = styled.button`
    left: 0!important;
    position: absolute;
    z-index:999; 


`;

const slideUp = keyframes`${slideInUp}`;

export const SideInUpFast = styled.div`
  animation: 1s ${slideUp};
`;

export const SideInUpSlow = styled.div`
  animation: 1.5s ${slideUp};
`;