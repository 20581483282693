import React, {memo} from 'react';
import { Link } from 'react-router-dom';
import { Footer as FooterStyled, Image, Line } from './styles';
import Logo from '../../assets/images/logo-soma-v2.png';

const Footer = () => {

  return (
    <FooterStyled className="box-footer d-flex justify-content-center align-items-center">
      <Link to="/">
        <Image src={Logo} />
      </Link>
      <Line/>
      <div className='d-flex flex-column'>
        <div className='text-block-78'>© 2020 Grupo Soma</div>
      </div>
    </FooterStyled>
  );
};

export default memo(Footer);
