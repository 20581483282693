import React, { useEffect, useState } from 'react';
import InternalPageBanner from '../../../components/InternalPageBanner';
import { PrimaryTitle, LoadMoreBtn } from '../../../styles/base';
import { Col, Row, Spinner } from 'reactstrap';
import CourseCard from '../../../components/Course/Card';
import { fetchCourses, fetchCoursesCategories } from '../../../services/endpoints/lms/courses/online';
import Placeholder from '../../../assets/images/placeholder.jpg';
import Base from '../../../theme/Base';
import { fetchBannersByGroup } from '../../../services/endpoints/cms/banners';

const CourseOnline = (props) => {

  const [courses, setCourses] = useState([]);
  const [banners, setBanners] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [loadingPage, setloadingPage] = useState(false);
  const [countPage, setCountPage] = useState(0);
  const [categoriesList, setCategoriesList] = useState([]);
  const [categoryTitle, setCategoryTitle] = useState('Categorias');
  const [category, setCategory] = useState('');
  const [categoryOpen, setCategoryOpen] = useState('');
  const [categoryLoading, setCategoryLoading] = useState(true);

  // let orderBy = 'position';
  // let recommended = '';

  // if(props.location.search === '?order=views'){
  //   orderBy = 'views';
  // }else if(props.location.search === '?order=recommended_courses') {
  //   recommended = 'recommended_courses';
  // }

  const perPage = 9;

  const loadMore = () => {
    setPage(prev => (prev + 1))
    setloadingPage(true)

    const paramsLoad = {
      perPage: perPage,
      page: page,
      'order[position asc, courses.created_at]': 'desc'
    }

    if (category) {
      Object.assign(paramsLoad, { category_id: category })
    }

    fetchCourses(paramsLoad)
      .then((loadMore) => {
        let temp = courses;
        setCourses(temp.concat(loadMore.data))
        setloadingPage(false)
      })
      .catch((err) => {
        console.log('Erro ao buscar cursos', err)
      })
  }

  const openCategory = () => {
    setCategoryOpen(!categoryOpen)
  }

  const changeCategory = (category_slug, category_title = 'Categorias') => {
    if(!category_slug){
      setCategoryTitle(category_title);
      setCategory('');
    }
    if (category_slug !== category && !!category_slug) {
      setCategoryLoading(true);
      setCategoryTitle(category_title);
      setCategory(category_slug);
    }
    setCategoryLoading(true)
  }

  useEffect(() => {
    /* Total de cursos ativos por categoria  */
    const paramsCount = {}

    if (category) {
      Object.assign(paramsCount, { category_id: category })
    }

    fetchCourses(paramsCount)
      .then(res => {
        if (res.data.length % perPage >= 1) {
          setCountPage(Math.trunc(res.data.length / perPage) + 1)
        } else {
          setCountPage(Math.trunc(res.data.length / perPage))
        }
      })
    /***********/

    /* Categorias vinculadas a cursos  */
    fetchCoursesCategories()
      .then(res => {
        setCategoriesList(res.data);
      })
    /**************/

    fetchBannersByGroup(3)
      .then((banner) => {
        if (banner.data.length > 0 && banner.data[0] !== null && banner.data[0].active) {
          setBanners(banner.data[0] || []);
        }
      })
      .catch((err) => {
        console.log('Erro ao buscar banner - cursos', err)
      })

    const params = {
      perPage: perPage,
      page: 0,
      'order[position asc, courses.created_at]': 'desc'
    }

    if (category) {
      Object.assign(params, { category_id: category })
    }

    fetchCourses(params)
      .then((coursesOnline) => {
        setCourses(coursesOnline.data || []);
        setLoading(false);
        setCategoryLoading(false);
      })
      .catch((err) => {
        console.log('Erro ao buscar cursos', err)
      })
  }, [category]);

  return (
    <Base>
      {loading ?
        <Row className='m-0'>
          <Col xs={12} className='my-5 text-center'>
            <Spinner color="dark" />
          </Col>
        </Row> :
        <>
          {banners && (
            <InternalPageBanner
              className='mb-5'
              content={banners}
            />
          )}

          <div className="w-container mb-5">
            <div className="title-category d-flex justify-content-between align-items-center pr-3">
              <PrimaryTitle>Cursos</PrimaryTitle>
              <div className="category-filter" onClick={e => openCategory()}>
                <span>{categoryTitle} <i className="fas fa-chevron-down " /></span>
                <ul className={`category-list ${categoryOpen ? 'open' : ''}`}>
                  <li onClick={e => changeCategory(false)}>Todos</li>
                  {categoriesList.length > 0 && categoriesList.map(category => (
                    <li key={category.id} onClick={e => changeCategory(category.id, category.title)}>{category.title}</li>
                  ))}
                </ul>
              </div>
            </div>
            <Row>
              {categoryLoading ? (
                <Row className='w-100'>
                  <Col xs={12} className='my-5 text-center'>
                    <Spinner color="dark" />
                  </Col>
                </Row>
              ) : (
                courses.length === 0 ? (
                  <Col xs={12} className='my-5 text-center'>
                    <h2 className="text-center">Nenhum curso dessa categoria</h2>
                  </Col>
                ) : (
                  courses.map(course => {
                    // let progress = '';
                    // if (course.course_stats.length === 0) {
                    //   progress = 0;
                    // } else if (
                    //   course.course_stats.length &&
                    //   course.course_stats[0].course_status === "CONCLUIDO") {
                    //   progress = 100;
                    // } else if (
                    //   course.course_stats.length &&
                    //   course.course_stats[0].progress) {
                    //   progress = Math.round(course.course_stats[0].progress);
                    // }
                    return (
                      <Col key={course.id} xs={12} md={4} className='my-3 my-md-3'>
                        <CourseCard
                          image={course.image !== null ? course.image.urlPublica : Placeholder}
                          duration={course.duration}
                          url={
                            course.course_stats.length && course.course_stats[0].course_status === "EM_ANDAMENTO" && course.course_stats[0].current_lesson_time !== null
                              ? `/lms/courses/${course.id}?hasresume=true`
                              : `/lms/courses/${course.id}`
                          }
                          tags={course.course_categories}
                          title={course.title}
                          progress={course.progress}
                        />
                      </Col>
                    )
                  })
                )
              )}
            </Row>
            {page < countPage ?
              loadingPage ?
                <Row className='m-0'>
                  <Col xs={12} className='my-5 text-center'>
                    <Spinner color="dark" />:
                  </Col>
                </Row>
                :
                <LoadMoreBtn onClick={e => loadMore()}>Carregar Mais</LoadMoreBtn>
              :
              <></>
            }
          </div>
        </>
      }
    </Base>
  );
};

export default CourseOnline;
