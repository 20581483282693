import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { fetchCourses } from '../../../services/endpoints/lms/courses/online';
import Placeholder from '../../../assets/images/placeholder.jpg';
import { PrimaryTitle, BtnPrimary } from '../../../styles/base';
import ScrollAnimation from 'react-animate-on-scroll';
import CourseCard from '../../Course/Card';


const MostWatched = () => {

  const [courses, setCourses] = useState([]);

  const getMostWatched = async () => {
    try {
      const response = await fetchCourses({
        page: 0,
        perPage: 4,
        'order[published_at]': 'desc',
        highlight: true
      });
      const { data } = response;
      return data;

    } catch (error) {
      console.error('MostWatched.getMostWatched', error);
      return [];
    }
  }

  useEffect(() => {
    const init = async () => {
      setCourses(await getMostWatched())
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ScrollAnimation className='w-container mb-5' animateIn='fadeIn' duration={0.8}>
      <PrimaryTitle>Cursos <br />Em Destaque</PrimaryTitle>
      <Row>
        {courses.map(course => {

          let progress = '';
          if (course.course_stats.length === 0) {
            progress = 0;
          } else if (
            course.course_stats.length &&
            course.course_stats[0].course_status === "CONCLUIDO") {
            progress = 100;
          } else if (
            course.course_stats.length &&
            course.course_stats[0].progress) {
            progress = Math.round(course.course_stats[0].progress);
          }

          return (
            <Col key={course.id} xs={12} md={3} className='my-3 my-md-3'>
              <CourseCard
                image={course.image !== null ? course.image.urlPublica : Placeholder}
                duration={course.duration}
                url={
                  course.course_stats.length &&
                    course.course_stats[0].course_status === "EM_ANDAMENTO" &&
                    course.course_stats[0].current_lesson_time !== null
                    ? `/lms/courses/${course.id}?hasresume=true`
                    : `/lms/courses/${course.id}`
                }
                tags={course.course_categories}
                title={course.title}
                progress={progress}
              />
            </Col>
          )
        })}
      </Row>
      <BtnPrimary className='di' to='/lms/courses'>Ver todos</BtnPrimary>
    </ScrollAnimation>
  );
};

export default MostWatched;