/** @format */

import React, { memo } from "react";
import { useHistory } from 'react-router-dom';
import PropTypes from "prop-types";
import api from "../../services/api";

const FilePDF = (props) => {
  const { url, id, updateStats, thumbnail, last, hasExam } = props;
  const history = useHistory();

  const setTrailsStats = (time, nextLesson) => {
    api.post(`/lms/trail_stats`, {
      trail: {
        id: props.trailStats.trailId,
      },
      user_iri: atob(localStorage.getItem(`user-endpoint`)),
      current_course: props.trailStats.currenCourse,
      current_lesson_course: nextLesson
        ? props.trailStats.currenteLesson + 1
        : props.trailStats.currenteLesson,
      current_lesson_course_time: time,
    });
  };

  const initCourseStats = async () => {
    try {
      const response = await api.get(
        `/lms/course_stats?wherein[course.id]=${props.courseStats.courseId}`
      );
      const { data } = response;

      if (data.length === 0) {
        api.post(`/lms/course_stats`, {
          completion_at: null,
          course: {
            id: props.courseStats.courseId,
          },
          course_status: "EM_ANDAMENTO",
          current_lesson: 0,
          current_lesson_time: 0,
          progress: 0,
          user_iri: atob(localStorage.getItem(`user-endpoint`)),
          watcheds_lessons: 0,
        });
      }
    } catch (error) {
      console.error("SambaVideo.initCourseStats", error);
    }
  };

  const setCourseStats = async (time, nextLesson) => {
    try {
      const response = await api.get(
        `/lms/course_stats?wherein[course.id]=${props.courseStats.courseId}`
      );
      const { data } = response;

      if (data.length) {
        api.put(`/lms/course_stats/${data[0].id}`, {
          current_lesson: nextLesson
            ? props.courseStats.currenteLesson + 1
            : props.courseStats.currenteLesson,
          current_lesson_time: time,
        });
      }
    } catch (error) {
      console.error("SambaVideo.setCourseStats", error);
    }
  };

  const refresh = () => {
    initCourseStats();
    api
      .post(`/general/watcheds`, {
        user_iri: atob(localStorage.getItem(`user-endpoint`)),
        progress: 0,
        content_iri: `/lms/lesson_contents/${id}`,
        watched: false,
      })
      .then((resp) => {
        api
          .post(`/general/watcheds`, {
            user_iri: atob(localStorage.getItem(`user-endpoint`)),
            progress: 100,
            content_iri: `/lms/lesson_contents/${id}`,
            watched: true,
          })
          .then((resp) => {
            updateStats();
            if (props.trailStats && last && !hasExam) {
              history.go();
            }
            if (props.courseStats) {
              setCourseStats(0, true);
            } else if (props.trailStats) {
              setTrailsStats(0, true);
            }
            props.autoNextLesson()
          });
      });
  };

  return (
    <>
      <div className="mobile-pdf">
        <img src={thumbnail} alt="thumbnail" />
        <a href={url} target="_blank" rel="noopener noreferrer">
          Abrir
        </a>
      </div>
      <iframe
        className="pdf-frame"
        title="featuredPDFView"
        id="featuredPDFView"
        src={`${url}#toolbar=0&navpanes=0&scrollbar=0`}
      />
      {props.watched ? (
        <button className="buton-refresh watched">VISTO</button>
      ) : (
          <button className="buton-refresh" onClick={(e) => refresh()}>
            MARCAR COMO VISTO
          </button>
        )}
    </>
  );
};

FilePDF.propTypes = {
  url: PropTypes.string.isRequired,
};
export default memo(FilePDF);
