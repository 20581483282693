import React, { memo, useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import Base from '../../../theme/Base';
import Scorm from '../../../components/Course/Scorm';
import { fetchCoursesInternal } from '../../../services/endpoints/lms/courses/online';


const ScormShow = (props) => {

    const { course, setCourse } = useState({})

    useEffect(() => {
        (async () => {
            try {
                const response = await fetchCoursesInternal(window.location.pathname.replace('/lms/scorm/', '/lms/courses/'))
                const { data } = response;
                console.log(data);

                setCourse(data)
            } catch (error) {

            }
        })()
    }, []);

    return (
        <Base>
            <Row>
                <Col xs={12} md={9}>
                    <Scorm course={course} />
                </Col>
                <Col xs={12} md={3}>
                    <h1>Aqui vai a barra lateral</h1>
                </Col>
            </Row>
        </Base>
    );
};

export default memo(ScormShow);
