import styled from 'styled-components';

export const Card = styled.div`
    display: flex;
    padding: 21px;
    flex-direction: column;
    border: 1px solid transparent;
    border-radius: 16px;
    box-shadow: 0 10px 20px 0 rgba(14, 14, 14, 0.15);
    transition: transform 400ms ease, border 400ms ease;
    text-align: left;

    &:hover {
        border-color: #ddd;
        box-shadow: none;
        transform: translate(0px, 6px) scale(1);
    }

    p {
        margin: 10px 0 0;
        color: #333;
        font-weight: 500;
        font-size: 14px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        min-height: 42px;
    }

    h4 {
        margin: 15px 0;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
    }
`; 

export const ImageCont = styled.div`

    height: 0;
    overflow: hidden;
    position: relative;
    width: 100%;
    padding-bottom: 95%;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 0;
        left: 0;
        position: absolute
    }

`; 

export const BtnAvaliable = styled.button`
    display: block;
    padding: 10px 0;
    justify-content: center;
    align-items: center;
    border: 1px solid #00985f;
    border-radius: 16px;
    background-color: transparent;
    transition: transform 500ms ease, color 500ms ease, background-color 500ms ease;
    color: #00985f;
    font-weight: 500;
    text-align: center;

    &:hover {
        background-color: #00985f;
        transform: translate(0px, -5px);
        color: #fff;
    }
`;

export const BtnUnavaliable = styled.div`
    display: block;
    padding: 10px 0;
    justify-content: center;
    align-items: center;
    border: 1px solid #6e6e6e;
    border-radius: 16px;
    background-color: transparent;
    color: #6e6e6e;
    font-weight: 500;
    text-align: center;
`;

