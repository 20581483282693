import styled from 'styled-components';

export const SideBar = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 6px 13px -8px #6d6b6b;
    padding: 10px 0;
    margin: 0 15px;

    hr {
        width: 80%
        height: 1px;
        margin: 0px;
        background-color: #f0f0f0; 
        border: none;
     
        @media ( max-width :991px) {
            width: 1px;
            height: 60px;
        }
    }

    @media ( max-width :991px) {
        flex-direction: row;
        margin: 0;
    }
`;

export const SideBarItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px 0;
    width: 100%;
    cursor: pointer;

    h3 {
        font-family: Montserrat, sans-serif;
        color: #ffa100;
        font-size: 35px;
        font-weight: 700;
        margin: 10px 0;

        @media ( max-width :991px) {
            font-size: 25px;
        }
    }

    p {
        margin: 0;
        padding: 0;        
        color: #616161;
        font-size: 16px;
        
        @media ( max-width :991px) {
            font-size: 12px;
        }
    }
`;