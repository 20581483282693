import React, { useState, useEffect } from 'react';
import { PrimaryTitle } from '../../styles/base';
import { fetchPages } from '../../services/endpoints/cms/pages';
import Base from '../../theme/Base';

const Page = (props) => {

  const [ page, setPage ] = useState({});
  
  useEffect(() => {
    fetchPages({
      slug: window.location.pathname.replace('/p/','')
    })
    .then(response => {
        setPage(response.data[0] || {})
    })
  },[]);

  return (
    <Base>
      <div className="w-container my-5">
        <PrimaryTitle>Aula<br/>ao vivo</PrimaryTitle>
          
        <div dangerouslySetInnerHTML={{__html: page.content}}/>
            
      </div>
    </Base>
  );
};

export default Page;