import React from 'react';
import { BannerTop, BannerImg, BannerImgMobile, BannerText, SideInUpFast, SideInUpSlow } from './style';
import placeholder from '../../assets/images/placeholder.jpg';

const InternalPageBanner = (props) => {

    const imgDesk = props.content.file ? props.content.file.urlPublica : placeholder;
    const imgMobile = props.content.file_mobile ? props.content.file_mobile.urlPublica : imgDesk;

    return (
        <BannerTop className={`${!!props.content.title ? 'mask-text' : ''}`}>
            <BannerImg src={imgDesk} alt={props.content.title} />
            <BannerImgMobile src={imgMobile} alt={props.content.title} />
            <BannerText>
                {!!props.content.title &&
                    <SideInUpFast>
                        <h1>{props.content.title}</h1>
                    </SideInUpFast>
                }
                {!!props.content.description &&
                    <SideInUpSlow>
                        <p>{props.content.description.replace(/<[^>]*>?/gm, '')}</p>
                    </SideInUpSlow>
                }
            </BannerText>
        </BannerTop>
    )
};

export default InternalPageBanner; 