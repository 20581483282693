import styled, { keyframes } from 'styled-components';
import { slideInUp } from 'react-animations';

export const BannerTop = styled.div`
    position: relative;
    height: 0;
    padding-bottom: 17%;
    overflow: hidden;
    margin-bottom: 25px;

    @media screen and (max-width: 992px) {
        padding-bottom: 50%;
    }
    
    &.mask-text:after {
        content: '';
        background: linear-gradient(-97deg, rgba(0, 0, 255, 0), rgba(29, 28, 28, 0.6) 77%);
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
    }
`;

export const BannerImg = styled.img`
    position: absolute;
    object-fit: cover;
    object-position: top;
    width: 100%;
    height: 100%;

    @media screen and (max-width: 992px) {
        display: none;
    }
`;

export const BannerImgMobile = styled.img`
    position: absolute;
    object-fit: cover;
    object-position: top;
    width: 100%;
    height: 100%;
    display: none;

    @media screen and (max-width: 992px) {
        display: block;
    }
`;

export const BannerText = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 940px;
    width: 90%;
    padding: 10px;
    transform: translate(-50%, -50%);
    z-index: 1;

    h1 {
        color: #fff;
        font-size: 34px;
        margin-top: 0;
        margin-bottom: 0;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;  
        overflow: hidden;
        
        @media screen and (max-width: 992px) {
            font-size: 15px;
            line-height: 26px; 
            text-shadow: 1px 1px 3px #000;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;  
            overflow: hidden;
        }
    }

    p {
        margin: 10px 0 0;
        color: #fff;
        font-size: 12px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;  
        overflow: hidden;

        @media screen and (max-width: 992px) {
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;  
            overflow: hidden;
        }
    }
    
`;

const slideUp = keyframes`${slideInUp}`;

export const SideInUpFast = styled.div`
  animation: 1s ${slideUp};
`;

export const SideInUpSlow = styled.div`
  animation: 1.5s ${slideUp};
`;