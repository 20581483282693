/** @format */

import React, { memo, useState, useEffect } from "react";
import { Col, Row, Spinner, Modal } from "reactstrap";
import {
  BtnEsgotato,
  BtnMatricula,
} from "../../../components/Course/Line/style";
import Base from "../../../theme/Base";
import CourseSideBar from "../../../components/Course/SideBar";
import CouseInfo from "../../../components/Course/Info";
import { fetchCoursesInternal } from "../../../services/endpoints/lms/courses/online";
import { fetchPerformedExams } from "../../../services/endpoints/lms/provas";
import CouseMaterial from "../../../components/Course/Material";
import AulasProgress from "../../../components/Course/AulasProgress";
import placeholder from "../../../assets/images/placeholder.jpg";
import ContentMedia from "../../../components/ContentMedia";
import { BtnCourseExam, LessonExamBox, LessonExam } from "./style";
import HandleViewContent from "../../../components/ContentMedia/handleViewContent";
import Slider from "react-slick";
import Scorm from "../../../components/Course/Scorm";

const GetAprovedExames = ({ id, keytype, functionExam, lesson_exam }) => {
  const [note, setNote] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchPerformedExams({
          user_iri: atob(window.localStorage.getItem("user-endpoint")),
        });

        if (response && response.data) {
          response.data.map((item) => {
            if (item.exam.id === id && item.approved) {
              if (item[keytype] !== null) {
                setNote(item.grade_obtained);
              }
            }
            return item;
          });
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [id, keytype]);

  if (keytype === "lesson") {
    return (
      <Col xs={12} md={3} className="my-3">
        <LessonExam
          disabled={note}
          className={`d-flex flex-column ${note ? "btn-aproved" : ""}`}
          onClick={(e) => (!note ? functionExam() : null)}
        >
          {lesson_exam.exam.title}
          {note && (
            <>
              <small>Aprovado</small>
              <small>
                <b>{Math.round(note)}%</b>
              </small>
            </>
          )}
        </LessonExam>
      </Col>
    );
  } else if (keytype === "course") {
    return (
      <BtnCourseExam
        disabled={note}
        className="d-flex flex-column"
        onClick={(e) => (!note ? functionExam() : null)}
      >
        {!note ? (
          <>Fazer Prova</>
        ) : (
          <>
            Aprovado
            <small>
              <b>{Math.round(note)}%</b>
            </small>
          </>
        )}
      </BtnCourseExam>
    );
  }
};

const CourseShow = (props) => {
  const [course, setCourse] = useState({});
  const [lessons, setLessons] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [loading, setLoading] = useState(true);
  const [atualAula, setAtualAula] = useState(0);
  const [courseFinish, setCourseFinish] = useState(false);
  const [lessonsCompleted, setLessonsCompleted] = useState(null);
  const [examAvaliable, setExamAvaliable] = useState(false);
  const [arrowLesson, setArrowLesson] = useState(false);
  const [videoResume, setVideoResume] = useState(false);
  const [modal, setModal] = useState(props.location.search ? true : false);
  const [refresh, setRefresh] = useState(false);

  const toggle = () => setModal(!modal);

  const getReferences = (reference, course) => {
    if (reference === "lesson") {
      if (
        course &&
        course.hasOwnProperty("course_stats") &&
        course.course_stats.length &&
        course.course_stats[0].current_lesson !== null &&
        course.lessons[course.course_stats[0].current_lesson]
      ) {
        return course.course_stats[0].current_lesson;
      }
      return 0;
    } else if (reference === "resume") {
      if (
        course &&
        course.hasOwnProperty("course_stats") &&
        course.course_stats.length &&
        course.course_stats[0].current_lesson_time !== null
      ) {
        return course.course_stats[0].current_lesson_time;
      }
      return 0;
    }
  };

  const restautar = () => {
    setAtualAula(getReferences("lesson", course));
    setVideoResume(true);
    document.body.classList.remove("modal-open");
    toggle();
  };

  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <button
        id="next-question"
        onClick={onClick}
        className={`${className}`}
      ></button>
    );
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <button
        id="prev-question"
        onClick={onClick}
        className={` ${className}`}
      ></button>
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const setExamInfo = (exam_id, object_id, redirect) => {
    localStorage.setItem(
      "exam_info",
      JSON.stringify({
        exam_id: exam_id,
        object_id: object_id,
        redirect: redirect,
      })
    );
    window.location.href = "/lms/prova";
  };

  function order(a, b) {
    let comparison = 0;
    if (a.position > b.position) {
      comparison = 1;
    } else if (a.position < b.position) {
      comparison = -1;
    }
    return comparison;
  }

  const verifyStats = (index) => {
    fetchCoursesInternal(window.location.pathname)
      .then((course) => {
        setCourse(course.data || {});
        let temp = [];

        course.data.lessons.map((i) =>
          temp.push(
            i.lesson_stats.filter(
              (lesson_stat) =>
                lesson_stat.user_iri ===
                atob(localStorage.getItem("user-endpoint"))
            )[0] || {}
          )
        );

        if (index !== undefined) {
          if (index === 0) {
            setAtualAula(0);
          } else {
            setAtualAula(index);
          }
        }

        if (
          course.data.lessons[atualAula].lesson_stats.filter(
            (item) =>
              item.user_iri === atob(localStorage.getItem("user-endpoint"))
          ).length > 0 &&
          course.data.lessons[atualAula].lesson_stats.filter(
            (item) =>
              item.user_iri === atob(localStorage.getItem("user-endpoint"))
          )[0].lesson_status === "CONCLUIDO"
        ) {
          setExamAvaliable(true);
        } else {
          setExamAvaliable(false);
        }

        let stats_temp = course.data.course_stats.filter(
          (course_stat) =>
            course_stat.user_iri === atob(localStorage.getItem("user-endpoint"))
        );
        if (
          stats_temp.length > 0 &&
          stats_temp[0].course_status === "CONCLUIDO"
        ) {
          setCourseFinish(true);
        } else {
          setCourseFinish(false);
        }
      })
      .catch((err) => {
        console.error("CourseShow.verifyStats.fetchCoursesInternal", err);
      });
  };

  const verifyLessonComplete = (id) => {
    if (
      lessonsCompleted !== null &&
      lessonsCompleted.filter((item) => item === id).length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    fetchCoursesInternal(window.location.pathname)
      .then((courseOnlineInternal) => {
        let courses = courseOnlineInternal.data;
        setCourse(courses || {});

        if (
          courses &&
          courses.lessons.length > 0 &&
          courses.lessons[atualAula].lesson_stats.filter(
            (item) =>
              item.user_iri === atob(localStorage.getItem("user-endpoint"))
          ).length > 0 &&
          courses.lessons[atualAula].lesson_stats.filter(
            (item) =>
              item.user_iri === atob(localStorage.getItem("user-endpoint"))
          )[0].lesson_status === "CONCLUIDO"
        ) {
          setExamAvaliable(true);
        } else {
          setExamAvaliable(false);
        }

        verifyStats(atualAula);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Erro ao buscar curso interno", err);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atualAula, refresh]);

  useEffect(() => {
    let lessons_completeds = [];

    if (course && course.hasOwnProperty("lessons") && course.lessons.length > 0) {
      fetchPerformedExams({
        user_iri: atob(window.localStorage.getItem("user-endpoint")),
      })
        .then((res) => {
          const getExamAproval = (id) => {
            return (
              res.data.filter((item) => item.exam.id === id && item.approved)
                .length > 0
            );
          };

          course.lessons.sort(order).map((i) => {
            if (
              i.lesson_stats.filter(
                (item) =>
                  item.user_iri === atob(localStorage.getItem("user-endpoint"))
              ).length > 0 &&
              i.lesson_stats.filter(
                (item) =>
                  item.user_iri === atob(localStorage.getItem("user-endpoint"))
              )[0].lesson_status === "CONCLUIDO" &&
              i.lesson_exams.length === 0
            ) {
              lessons_completeds.push(i.id);
            } else if (
              i.lesson_stats.filter(
                (item) =>
                  item.user_iri === atob(localStorage.getItem("user-endpoint"))
              ).length > 0 &&
              i.lesson_stats.filter(
                (item) =>
                  item.user_iri === atob(localStorage.getItem("user-endpoint"))
              )[0].lesson_status === "CONCLUIDO" &&
              i.lesson_exams.length > 0
            ) {
              let count_exam = 0;

              i.lesson_exams.map((lesson_exam) => {
                return !lesson_exam.exam.mandatory_approval ||
                  getExamAproval(lesson_exam.exam.id)
                  ? count_exam++
                  : null;
              });

              return i.lesson_exams.length === count_exam
                ? lessons_completeds.push(i.id)
                : null;
            }
            return null;
          });

          setLessonsCompleted(lessons_completeds);
        })
        .catch((err) => {
          console.error("Erro ao buscar provas de aulas", err);
        });

      setLessons(course.lessons[atualAula] || []);
      setMaterials(course.lessons[atualAula].lesson_support_materials || []);
    }
  }, [atualAula, course, refresh]);

  const verifyNextLesson = async () => {
    try {
      if(course.lessons.length - 1 > atualAula && verifyLessonComplete(lessons.id)){
        setAtualAula(atualAula + 1)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (!!course.lessons && !!course.lessons.length) {
      if (course.lessons[course.lessons.length - 1].id === lessons.id) {
        setArrowLesson(false);
      } else {
        setArrowLesson(verifyLessonComplete(lessons.id));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessons, lessonsCompleted]);

  const courseStats = {
    courseId: course.id,
    currenteLesson: atualAula,
  };

  return (
    <Base>
      {loading === true ? (
        <Row className="m-0">
          <Col xs={12} className="my-5 text-center">
            <Spinner color="dark" />
          </Col>
        </Row>
      ) : (
        <>
          {course.scorm ? (
            <>
              <div className="w-container p-0">
                <Row className="my-5">
                  <Col xs={12} md={10}>
                    <Scorm
                      course={course}
                      setRefresh={setRefresh}
                      refresh={refresh}
                    />
                  </Col>
                  <Col xs={12} md={2}>
                    <CourseSideBar
                      aulas={course.lessons.length}
                      course_id={course.id}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={9}>
                    <CouseInfo
                      title={course.title}
                      description={course.summary}
                    />
                  </Col>
                </Row>
              </div>
              {course.course_exams.length > 0 &&
                course.course_exams[0].exam &&

                !!course && course.course_stats && !!course.course_stats.length && course.course_stats[0].course_status === 'CONCLUIDO' && (
                  <div className="exam-area">
                    <div className="w-container p-0">
                      <Row className="d-flex justify-content-center align-items-center">
                        <Col xs={12}>
                          <Row className="d-flex justify-content-center align-items-center">
                            <Col xs={12} md={9}>
                              <div className="course-exame">
                                <h2>PARABÉNS!</h2>
                                <p>
                                  Você concluiu 100% de todas as aulas neste
                                  curso. Para finalizar realize a prova.
                                </p>
                              </div>
                            </Col>
                            <Col xs={12} md={3}>
                              <GetAprovedExames
                                id={course.course_exams[0].exam.id}
                                keytype="course"
                                functionExam={() =>
                                  setExamInfo(
                                    course.course_exams[0].exam.id,
                                    `course_id=${course.id}`,
                                    window.location.pathname
                                  )
                                }
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
            </>
          ) : course.lessons.length < 1 ? (
            <div className="w-container p-0">
              <Col xs={12} className="my-5 text-center">
                <h3 className="text-center">
                  Ainda não existem aulas nesse curso
                </h3>
              </Col>
            </div>
          ) : (
            <>
              <Modal
                isOpen={modal}
                toggle={toggle}
                className="modal-curso-presencial "
                centered={true}
              >
                <i
                  className="fas fa-times close-classroom-modal"
                  onClick={toggle}
                />
                <p className="px-2 text-center">Continuar de onde parou?</p>
                <Row className="pb-4 justify-content-around w-100 p-0 px-md-5">
                  <BtnMatricula
                    className="m-0 ml-md-5 mb-2"
                    onClick={(e) => restautar()}
                  >
                    Sim
                  </BtnMatricula>
                  <BtnEsgotato
                    className="m-0 mr-md-5 pointer mb-2"
                    onClick={toggle}
                  >
                    Não
                  </BtnEsgotato>
                </Row>
              </Modal>

              <div className="w-container p-0">
                {(course && course.hasOwnProperty("id")) && (
                  <Row className="my-5">
                    <Col xs={12} md={9}>
                      <HandleViewContent.Provider value={{ verifyStats }}>
                        {course.lessons[atualAula].lesson_contents.length > 0 && (
                            <>
                              {arrowLesson && (
                                <i
                                  onClick={(e) => setAtualAula(atualAula + 1)}
                                  className="fas fa-chevron-right next-lesson-arrow"
                                />
                              )}
                              <ContentMedia
                                video={
                                  course.lessons[atualAula].lesson_contents[0]
                                }
                                courseStats={courseStats}
                                videoResume={videoResume}
                                autoNextLesson={verifyNextLesson}
                                watched={
                                  course &&
                                    course.hasOwnProperty("lessons") &&
                                    course.lessons.length &&
                                    course.lessons[atualAula].lesson_stats.length &&
                                    course.lessons[
                                      atualAula
                                    ].lesson_stats[0].hasOwnProperty(
                                      "lesson_status"
                                    )
                                    ? course.lessons[atualAula].lesson_stats[0]
                                      .lesson_status === "CONCLUIDO"
                                    : false
                                }
                                thumbnail={
                                  course.lessons[atualAula].lesson_contents[0]
                                    .thumbnail
                                    ? course.lessons[atualAula].lesson_contents[0]
                                      .thumbnail.urlPublica
                                    : placeholder
                                }
                              />
                            </>
                          )}
                      </HandleViewContent.Provider>
                    </Col>
                    <Col xs={12} md={3}>
                      <CourseSideBar
                        aulas={course.lessons.length}
                        course_id={course.id}
                      />
                    </Col>
                  </Row>
                )}
              </div>
              <div className="w-container p-0">
                <Row>
                  <Col xs={12} md={9}>
                    <CouseInfo
                      title={lessons.title}
                      description={lessons.description}
                    />
                  </Col>
                </Row>
              </div>
              <div className="w-container p-0">
                <Row>
                  <Col xs={12}>
                    {materials.length > 0 && (
                      <CouseMaterial material={materials} />
                    )}
                  </Col>
                </Row>
              </div>
              {examAvaliable &&
                course.lessons[atualAula].lesson_exams.length > 0 &&
                course.lessons[atualAula].lesson_exams[0].exam !== null && (
                  <div className="w-container p-0 mt-5">
                    <Row>
                      <Col xs={12}>
                        <LessonExamBox>
                          <h2>Atividades</h2>
                          {!verifyLessonComplete(
                            course.lessons[atualAula].id
                          ) ? (
                            <p>
                              Realize{" "}
                              {course.lessons[atualAula].lesson_exams.length > 1
                                ? "as atividades"
                                : "a atividade"}{" "}
                              para concluir a aula.
                            </p>
                          ) : (
                            ""
                          )}
                          <Row>
                            {course.lessons[atualAula].lesson_exams.map(
                              (lesson_exam) => {
                                if (lesson_exam.exam) {
                                  return (
                                    <GetAprovedExames
                                      key={lesson_exam.exam.id}
                                      lesson_exam={lesson_exam}
                                      id={lesson_exam.exam.id}
                                      keytype="lesson"
                                      functionExam={() =>
                                        setExamInfo(
                                          lesson_exam.exam.id,
                                          `lesson_id=${course.lessons[atualAula].id}`,
                                          window.location.pathname
                                        )
                                      }
                                    />
                                  );
                                }
                                return null;
                              }
                            )}
                          </Row>
                        </LessonExamBox>
                      </Col>
                    </Row>
                  </div>
                )}

              <div className="w-container p-0">
                <Row className="my-5">
                  <Col xs={12}>
                    <h2>Aulas</h2>
                    <Slider {...settings} className="slider-course">
                      {course.lessons.sort(order).map((lesson, i) => (
                        <div
                          key={lesson.id}
                          className="course-slide-item"
                          xs={12}
                          md={3}
                          onClick={
                            i === 0 ||
                              !course.force_lessons_sequence ||
                              (course.lessons.sort(order)[i - 1] !== undefined &&
                                verifyLessonComplete(course.lessons[i - 1].id))
                              ? (e) => verifyStats(i)
                              : null
                          }
                        >
                          <AulasProgress
                            image={
                              lesson.lesson_contents.length > 0 &&
                                lesson.lesson_contents[0].thumbnail !== null
                                ? lesson.lesson_contents[0].thumbnail.urlPublica
                                : placeholder
                            }
                            title={lesson.title}
                            watched={verifyLessonComplete(lesson.id)}
                            unlocked={
                              i === 0 ||
                              !course.force_lessons_sequence ||
                              (course.lessons.sort(order)[i - 1] !==
                                undefined &&
                                verifyLessonComplete(
                                  course.lessons.sort(order)[i - 1].id
                                ))
                            }
                          />
                        </div>
                      ))}
                    </Slider>
                  </Col>
                </Row>
              </div>
              {course.course_exams.length > 0 &&
                course.course_exams[0].exam &&
                courseFinish && (
                  <div className="exam-area">
                    <div className="w-container p-0">
                      <Row className="d-flex justify-content-center align-items-center">
                        <Col xs={10}>
                          <Row className="d-flex justify-content-center align-items-center">
                            <Col xs={12} md={9}>
                              <div className="course-exame">
                                <h2>PARABÉNS!</h2>
                                <p>
                                  Você concluiu 100% de todas as aulas neste
                                  curso. Para finalizar realize a prova.
                                </p>
                              </div>
                            </Col>
                            <Col xs={12} md={3}>
                              <GetAprovedExames
                                id={course.course_exams[0].exam.id}
                                keytype="course"
                                functionExam={() =>
                                  setExamInfo(
                                    course.course_exams[0].exam.id,
                                    `course_id=${course.id}`,
                                    window.location.pathname
                                  )
                                }
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
            </>
          )}
        </>
      )}
    </Base>
  );
};

export default memo(CourseShow);
