import React from 'react';
import { Info } from './style';

const CourseInfo = (props) => (
    <Info>
        <h1>{props.title}</h1>
        <div dangerouslySetInnerHTML={{__html: props.description}}/>
    </Info>
);

export default CourseInfo;