import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { fetchTrails } from "../../../services/endpoints/lms/trails";
import Placeholder from "../../../assets/images/placeholder.jpg";
import { PrimaryTitle, BtnPrimary } from "../../../styles/base";
import ScrollAnimation from "react-animate-on-scroll";
import CourseCard from "../../Course/Card";

const Recommended = () => {
  const [trails, setTrails] = useState([]);

  // const getQuantity = (arr, value) => {
  //   let quantity = 0;
  //   arr.forEach((item) => {
  //     if (item === value) {
  //       quantity++;
  //     }
  //   });

  //   return quantity;
  // };

  const getImage = (item) => {
    if (item.thumbnail) {
      return item.thumbnail.urlPublica;
    }
    if (
      item.trails_courses.length &&
      item.trails_courses[0].hasOwnProperty("course") &&
      item.trails_courses[0].course &&
      item.trails_courses[0].course.hasOwnProperty("image") &&
      item.trails_courses[0].course.image !== null &&
      item.trails_courses[0].course.image.hasOwnProperty("urlPublica")
    ) {
      return item.trails_courses[0].course.image.urlPublica;
    }
    return Placeholder;
  };

  const getRecommended = async () => {
    try {
      const response = await fetchTrails({
        perPage: 3,
        page: 0,
      });
      const { data } = response;

      let temp = [];

      data.forEach((item) => {
        let tempProgress = [];
        item.trails_courses.forEach((item) => {
          if (
            item.course &&
            !!item.course.length &&
            !!item.course.course_stats.length
          ) {
            tempProgress.push(item.course.course_stats[0].progress);
          }
        });
        // let trailProgress =
        //   (getQuantity(tempProgress, 100) * 100) / item.trails_courses.length;

        temp.push({
          id: item.id,
          image: getImage(item),
          title: item.title,
          category_courses:
            item.trails_courses.length > 0 && item.trails_courses[0].course
              ? item.trails_courses[0].course.course_categories
              : [],
          progress: item.progress,
        });
      });

      return temp;
    } catch (error) {
      console.error("Recommended.getRecommended", error);
    }
  };

  useEffect(() => {
    const initialTrail = async () => {
      setTrails(await getRecommended());
    };
    initialTrail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ScrollAnimation className="w-container" animateIn="fadeIn" duration={0.8}>
      <PrimaryTitle>Trilhas Recentes</PrimaryTitle>
      <Row>
        {trails.map((trail) => (
          <Col key={trail.id} xs={12} md={4} className="my-3 my-md-3">
            <CourseCard
              title={trail.title}
              image={trail.image}
              url={`/lms/trails/${trail.id}`}
              tags={trail.category_courses}
              progress={trail.progress}
            />
          </Col>
        ))}
      </Row>
      <BtnPrimary to="/lms/trails">Ver todas</BtnPrimary>
    </ScrollAnimation>
  );
};

export default Recommended;
