import React, { useState, useEffect } from 'react';
import { Row } from 'reactstrap';
import { fetchFeatureGroups } from '../../../services/endpoints/cms/features';
import { orderById } from '../../../util/orderById'

import Category from './category';

const Categories = () => {

  const [ categories, setCategories ] = useState([]);

  const getFeatures = async () => {
    try {
      const response = await fetchFeatureGroups({id: 1});
      const { data } = response;

      setCategories(data[0].features.sort(orderById));
    } catch (error) {
      console.error('Category.getFeatures', error);
    }
  }

  useEffect(()=>{
    getFeatures();
  },[]);

  return (
    <div className="categorias">
      <div className="w-container">
        <Row>
          {categories.map(category => (
            <Category
              key={category.id}
              icon={category.icon}
              title={category.title}
              description={category.description}
              url={category.link || '#'}
            />
          ))}
        </Row>
      </div>
    </div>
  );
};

export default Categories;