import styled from 'styled-components';

export const Info = styled.div`
    padding: 30px 0;

    @media ( max-width :991px) {
        padding: 0;
    }

    h1 {
        font-size: 35px;
        line-height: 1;
        margin: 20px 0 10px;

        @media ( max-width: 992px) {
            font-size: 20px;
        }
    }

    div {
        font-size: 16px;
        margin: 20px 0 10px;
    }
`;