import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const LinkCourse = styled(Link)`
    display: block;
    border: 1px solid transparent;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 13px -8px #646262;
    transition: box-shadow 400ms ease, transform 400ms ease;
    text-align: left;
    text-decoration: none;
    overflow: hidden;
    width: 100%;

    &:hover {
        border-color: transparent;
        box-shadow: 0 10px 20px 0 rgba(14, 14, 14, 0.15);
        -webkit-transform: scale(1.02) translate(0px, -6px);
        -ms-transform: scale(1.02) translate(0px, -6px);
        transform: scale(1.02) translate(0px, -6px);
        text-decoration: none;
    }
`;

export const DivCourse = styled.div`
    display: block;
    border: 1px solid transparent;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 13px -8px #646262;
    transition: box-shadow 400ms ease, transform 400ms ease;
    text-align: left;
    text-decoration: none;
    overflow: hidden;
    width: 100%;
    cursor: pointer;

    &:hover {
        border-color: transparent;
        box-shadow: 0 10px 20px 0 rgba(14, 14, 14, 0.15);
        -webkit-transform: scale(1.02) translate(0px, -6px);
        -ms-transform: scale(1.02) translate(0px, -6px);
        transform: scale(1.02) translate(0px, -6px);
        text-decoration: none;
    }
`;

export const CardHeader = styled.div`
    width: 100%;

    > div {
        height: 0;
        overflow: hidden;
        position: relative;
        width: 100%;
        padding-bottom: 68%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
            top: 0;
            left: 0;
            position: absolute
        }

        small {
            position: absolute;
            bottom: 10px;
            right: 10px;
            color: #aca3a3;
            font-size: 14px;
            text-align: right;
            text-decoration: none;
            z-index: 1;
        }

        
        i.fa-play-circle {
            position: absolute;
            margin: 0;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #bcbcbc;
            font-size: 50px;
            text-decoration: none;
            z-index: 1;
        }

        &:after {
            content: '';
            background: linear-gradient(180deg, rgba(0, 0, 255, 0), rgba(29, 28, 28, 0.6) 77%);
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
        }
    }
`;

export const CardBody = styled.div`
    padding: 15px;

    small {
        color: red;
        font-size: 10px;
        font-weight: 600;
        text-decoration: underline;
        text-transform: uppercase;
    }

    h3 {
        color: #333;
        font-size: 17px;
        font-weight: 700;
        text-decoration: none;
        text-transform: none;
        line-height: 1;

    }
`;

export const ProgressText = styled.div`
    position: absolute;
    bottom: 5px;
    left: 2px;
    z-index: 1;
    width: 100%;
    color: #bcbcbc;
`;



export const ProgressBar = styled.div`
    height: 8px;
    background: #bcbcbc;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    display: flex;

    div {
        background: #00985f;
        width: ${(props) => props.w || 0}%;
        display: flex;
    }
`;