/* eslint-disable */
import React, { useState, useEffect } from "react";
import { PrimaryTitle } from "../../styles/base";
import Base from "../../theme/Base";
import { Col, Row, Spinner } from "reactstrap";
import Placeholder from "../../assets/images/placeholder.jpg";
import { CertificateCard, CertCardHead, CertCardBody } from "./style";
import { fetchPerformedExams } from "../../services/endpoints/lms/provas";
import { fetchCursosConcluidos } from "../../services/endpoints/lms/cursos_conluidos";
import axios from "axios";
import { getToken } from "../../services/auth";
import moment from "moment";
import { convDate } from "../../util/convDate";

const Search = (props) => {
  const [certificates, setCertificates] = useState([]);
  const [loading, setLoading] = useState(true);

  const compare = (a, b) => {
    let comparison = 0;
    if (a.grade_obtained > b.grade_obtained) {
      comparison = 1;
    } else if (a.grade_obtained < b.grade_obtained) {
      comparison = -1;
    }
    return comparison * -1;
  };

  const getCertificado = (course_id, name) => {
    axios({
      url: `${process.env.REACT_APP_API_URL}/lms/courses/${course_id}/certificate_issuance`,
      method: "GET",
      responseType: "arraybuffer",
      headers: { Authorization: getToken() },
    }).then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.download = "TESTE";
      link.target = "_blank";
      link.setAttribute("download", `Certificado - ${name}.pdf`);
      document.body.appendChild(link);
      link.click();
      window.open(url);
    });
  };

  useEffect(() => {
    let cert = [];
    (async () => {
      try {
        const coursesFinished = await fetchCursosConcluidos({
          user_iri: atob(window.localStorage.getItem("user-endpoint")),
        });

        coursesFinished.data.length &&
          coursesFinished.data.map((item) => {
            if (item.course.course_exams.length === 0) {
              return cert.push({
                id: item.course.id,
                course: item.course,
                applied_at: item.completion_at,
                tentatives: 1,
                highest_grade: "Não disponível",
              });
            } else if (
              item.course.course_exams.length > 0 &&
              !item.course.course_exams[0].exam.mandatory_approval
            ) {
              return cert.push({
                id: item.course.id,
                course: item.course,
                applied_at: item.completion_at,
                tentatives: 1,
                highest_grade: "Não disponível",
              });
            }
            return null;
          });
        const perfomedExamsFetch = await fetchPerformedExams({
          user_iri: atob(window.localStorage.getItem("user-endpoint")),
        });
        let temp = [];
        let diferentes = [];
        perfomedExamsFetch.data.length &&
          perfomedExamsFetch.data.map((i) => {
            return i.approved && i.course !== null && i.lesson === null
              ? temp.push({
                  id: i.id,
                  course: i.course,
                  grade_obtained: i.grade_obtained,
                  applied_at: i.applied_at,
                })
              : null;
          });

        const map = new Map();
        for (const item of temp.sort(compare)) {
          if (!map.has(item.course.id)) {
            map.set(item.course.id, true);
            diferentes.push(item);
          }
        }

        diferentes.map((item) => {
          let tentatives = temp.filter(
            (i) => i.course.id === item.course.id
          ).length;

          return cert.push({
            id: item.course.id,
            course: item.course,
            applied_at: item.applied_at,
            tentatives: tentatives,
            highest_grade: item.grade_obtained,
          });
        });

        setCertificates(
          cert.filter((item) => item.course.certificate_template !== null)
        );
        setLoading(false);
      } catch (error) {
        console.log("Erro ao buscar certificados", error);
      }
    })();
  }, []);

  return (
    <Base>
      {loading === true ? (
        <Row className="m-0">
          <Col xs={12} className="my-5 text-center">
            <Spinner color="dark" />
          </Col>
        </Row>
      ) : (
        <>
          <div className="w-container mt-5">
            <PrimaryTitle>Certificados</PrimaryTitle>
          </div>
          {false ? (
            <div className="w-container p-0">
              <Col xs={12} className="my-4 text-center">
                <h2 className="text-center">Nenhum certificado</h2>
              </Col>
            </div>
          ) : (
            <div className="w-container mb-5">
              <Row>
                {certificates.map((certificate) => (
                  <Col
                    key={certificate.id}
                    xs={12}
                    md={4}
                    className="my-3 my-md-3"
                  >
                    <CertificateCard>
                      <CertCardHead>
                        <img
                          src={
                            certificate.course.image !== null
                              ? certificate.course.image.urlPublica
                              : Placeholder
                          }
                          alt=""
                        />
                        <i className="fal fa-award" />
                        {/* <small>10 dias</small> */}
                      </CertCardHead>
                      <CertCardBody>
                        <h2>{certificate.course.title}</h2>
                        <p>
                          Conclusão:{" "}
                          {moment(convDate(certificate.applied_at)).format(
                            "DD/MM/YYYY"
                          )}
                        </p>
                        <p>Tentativas: {certificate.tentatives}</p>
                        <p className="mb-4">
                          Nota máxima:{" "}
                          {Math.round(certificate.highest_grade) ||
                            "Não disponível"}
                        </p>
                        <button
                          onClick={(e) =>
                            getCertificado(
                              certificate.course.id,
                              certificate.course.title
                            )
                          }
                        >
                          Baixar <i className="fal fa-arrow-to-bottom" />
                        </button>
                      </CertCardBody>
                    </CertificateCard>
                  </Col>
                ))}
              </Row>
            </div>
          )}
        </>
      )}
    </Base>
  );
};

export default Search;
