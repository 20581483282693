import styled from 'styled-components';

export const PointsBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0 30px;
    padding: 25px;
    border: 1px solid #ddd;
    border-radius: 16px;
    color: #333;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
   
    @media (max-width: 768px) {
        flex-direction: column
    } 
`;

export const Points = styled.span`
    color: #00985f;
    font-size: 25px;
    font-weight: 700;
    margin-left: 15px

    i {
        color: #00985f;
        font-size: 23px;
    }
`;