import styled from 'styled-components';

export const LiveFrame = styled.div`
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
`;

export const Frame = styled.iframe`
    position: absolute;
    border: none;
    width: 100%;
    height: 100%;
`;

export const LiveInfo = styled.div`
    margin: 60px 0 30px;

    h2 {
        font-size: 35px;
    }

    p {
        font-size: 14px;
        margin-top: 20px;
    }
`;