import styled from 'styled-components';

export const CourseBox = styled.div`
    display: flex;
    margin-top: 20px;
    padding: 25px 15px;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 10px 16px -8px rgba(0, 0, 0, 0.15);
    transition: box-shadow 400ms ease, transform 400ms ease;

    @media ( max-width: 768px) {
        justify-content: center
    }

    &:hover {
        border: 1px solid transparent;
        box-shadow: 0 10px 20px 0 rgba(14, 14, 14, 0.15);
        transform: scale(1.02) translate(0px, -6px);
    }
`;

export const MainDate = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
        border-radius: 8px;
        background-color: transparent;
        color: #00985f;
        font-size: 35px;
        font-weight: 500;
        margin: 0
    }

    small {
        margin-top: 0;
        padding-top: 3px;
        padding-bottom: 3px;
        background-color: transparent;
        color: #616161;
    }
`;

export const Andress = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    
    @media ( max-width: 768px) {
        text-align: center;
    }

    p {
        margin: 0;
        padding: 0;
        width: 100%;
    }
`;

export const CourseDescription = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;

    @media ( max-width: 768px) {
        align-items: left;
        text-align: center;
        width: 80%
        margin: auto
    }

    h3 {
        padding: 0;
        border: 1px solid #f8f8f8;
        color: #333;
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;  
        overflow: hidden;
    }

    p {
        margin: 0;
        padding-top: 3px;
        padding-bottom: 0px;
        color: #616161;
        font-size: 12px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  
        overflow: hidden;
    }
`;

export const BtnMatricula = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    padding: 9px 0;
    align-items: center;
    border: 1px solid #00985f;
    border-radius: 16px;
    transition: transform 500ms ease, color 500ms ease, background-color 500ms ease;
    color: #00985f;
    font-weight: 500;
    text-align: center;
    background: transparent;
    outline: none;

    &:hover {
        background-color: #00985f;
        transform: translate(0px, -5px);
        color: #fff;
    }

`;

export const BtnMatriculado = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    padding: 9px 0;
    align-items: center;
    border: 1px solid #00985f;
    border-radius: 16px;
    color: #fff!important;
    font-weight: 500;
    text-align: center;
    background: #00985f;
    transform: ease all 300ms;
    outline: none;

    &:hover {
        background: #d9534f;
        border: 1px solid #d9534f;

        span {
            display: none;
        }

        &:before {
            content:'Cancelar'
        }
    }
`;


export const BtnEsgotato = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    padding: 9px 0;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(51, 51, 51, 0.4);
    border-radius: 16px;
    background-color: transparent;
    color: #6e6e6e;
    font-weight: 500;
    outline: none;
    text-align: center;
`;

export const BtnCancel = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    padding: 9px 0;
    align-items: center;
    border-radius: 16px;
    color: #fff!important;
    font-weight: 500;
    text-align: center;
    transform: ease all 300ms;
    background: #d9534f;
    outline: none;
    border: 1px solid #d9534f;
`;