import React from 'react';
import { Profile } from './styles';

const ProfileInfo = (props) => {

  function logout() {
    window.location = `${process.env.REACT_APP_SIGNIN_URL}?logout=${btoa(document.location.origin)}`;
  }

  const nome = props.nome.replace(/(<[^>]*>)/g, '').split(' ')

  return (
    <Profile>
      <div className="name-area ml-3 ml-lg-0 desktop-menu-profile">
        <a href="/profile"><i className="fal fa-user-circle" /></a>
        <div className="dropdown-area">
          <h3>{`${nome[0]} ${nome[nome.length - 1]}`}</h3>
          <nav className="dropdown-list">
            <a href="/profile">Perfil</a>
            <button onClick={logout} className="btn-logout">Sair</button>
          </nav>
        </div>
      </div>

      <div className="name-area flex-column ml-3 ml-lg-0 mobile-menu-profile">
        <a href="/profile" className="d-flex align-items-center">
          <i className="fal fa-user-circle mr-1" />
          <span>{`${nome[0]} ${nome[nome.length - 1]}`}</span>
        </a>
        <nav className="dropdown-list mb-3">
          <button onClick={logout} className="btn-logout">Sair</button>
        </nav>
      </div>
    </Profile>
  )
};

export default ProfileInfo;