/** @format */

import React, { memo, useContext } from "react";
import SambaVideo from "./SambaVideo";
import VideoFile from "./VideoFile";
import FilePDF from "./FilePDF";
import ImageFile from "./ImageFile";
import HandleViewContent from "./handleViewContent";

const ContentMedia = ({
  video,
  watched,
  trailStats,
  courseStats,
  videoResume,
  thumbnail,
  last,
  hasExam,
  autoNextLesson,
  ...props
}) => {
  const { verifyStats } = useContext(HandleViewContent);


  const Medias = () => {
    switch (video.type) {
      case "SAMBA_MEDIA":
        return (
          <SambaVideo
            updateStats={verifyStats}
            contentType={video.contentType}
            lesson_content_iri={`/lms/lesson_content/${video.id}`}
            id={video.id}
            reference={video.samba_media_reference || ""}
            trailStats={trailStats}
            courseStats={courseStats}
            videoResume={videoResume}
            last={last}
            hasExam={hasExam}
            autoNextLesson={autoNextLesson}
          />
        );
      case "FILE":
        if (
          video.file &&
          (video.file.contenttype === "image/jpeg" ||
            video.file.contenttype === "image/png" ||
            video.file.contenttype === "image/jpg")
        ) {
          return (
            <ImageFile
              updateStats={verifyStats}
              watched={watched}
              src={video.file.urlPublica}
              alt={video.title}
              id={video.id}
              courseStats={courseStats}
              trailStats={trailStats}
              autoNextLesson={autoNextLesson}
              last={last}
              hasExam={hasExam}
            />
          );
        } else if (video.file && video.file.contenttype === "application/pdf") {
          return (
            <FilePDF
              updateStats={verifyStats}
              watched={watched}
              id={video.id}
              url={video.file.urlPublica}
              courseStats={courseStats}
              trailStats={trailStats}
              thumbnail={thumbnail}
              last={last}
              autoNextLesson={autoNextLesson}
              hasExam={hasExam}
            />
          );
        } else if (
          video.file &&
          (video.file.contenttype === "video/mp4" ||
            video.file.contenttype === "video/avi")
        ) {
          return (
            <VideoFile
              updateStats={verifyStats}
              id={video.id}
              url={video.file.urlPublica}
            />
          );
        } else {
          return null;
        }
      default:
        return video.file ? video.file.urlPublica : "";
    }
  };

  return (
    <div className={`medias ${video.type === "FILE" ? "content-file" : ""}`}>
      <Medias />
    </div>
  );
};

export default memo(ContentMedia);
