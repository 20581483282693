import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const HeaderStyled = styled.header`
    position: fixed;
    top: 0;
    width:100%
    min-height: 70px;
    background-color: #fff;
    box-shadow: 3px 3px 14px -9px rgba(0, 0, 0, 0.51);
    z-index: 999;
    transition: ease all 500ms; 
`;

export const HeaderProfile = styled.header`
  display: flex;
  align-items: center;
`;

export const ImageProfileHeader = styled.img`
  width: 30px;
  height: 30px;
  object-fit: cover;
  margin-top: 5px;
  border-radius: 50%;
  border: 3px solid #fff;
  margin-bottom: -15px;
`;

export const NameUserHeder = styled.span`
  font-size: 14px;
  font-weight: 600;
  margin-left: 15px;
`;
export const MenuHeader = styled.span`
  display: table;
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid #d2d2d2;
  margin-bottom: 70px;
`;
export const NavItem = styled.li`
  display: table-cell;
  width: 16.666%;
  white-space: nowrap;
  text-align: center;
`;

export const Link = styled(NavLink)`

`;

export const LiveStatus = styled.div`
  flex: 0 0 23%;
  max-width: 23%;
  font-size: 15px;
  line-height: 35px;
  font-weight: bold;
  position: relative;
  background-color: #ff0000;
  text-transform: uppercase;
  &:before {
    content: "";
    width: 12px;
    height: 12px;
    display: inline-block;
    background: #fff;
    margin: 0 7px;
    border-radius: 50%;
  }
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    top: 0;
    right: -10px;
    border-right: 10px solid transparent;
    border-top: 35px solid #ff0000
  }
`;

export const LiveTitle = styled.div`
  flex: 0 0 77%;
  max-width: 77%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 15px;
  font-style: italic;
`;

export const LiveMessage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f00;
  line-height: 35px;
  padding: 0 15px;
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  opacity: 0;
  transition: all 300ms linear;
  cursor: pointer;
`;

const widthButtonLive = 290;
const widthButtonLiveOffline = 70;
const widthButtonLiveOfflineSpace = widthButtonLive - widthButtonLiveOffline;

export const LiveButton = styled.div`
  width: ${widthButtonLive}px;
  background-color: #004c94;
  color: #ffffff;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow: hidden;
  border-radius: 5px; 
  &:hover ${LiveMessage}{
    opacity: 1;
  }
`;

export const LiveButtonOffline = styled.div`
  color: #d4d4d4;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid #d4d4d4;
  margin-left: ${widthButtonLiveOfflineSpace}px;
  width: ${widthButtonLiveOffline}px;
`;

export const LiveStatusOffline = styled.div`
  text-transform: uppercase;
  font-size: 15px;
  line-height: 35px;
  font-weight: bold;
  position: relative;
  &:before {
    content: "";
    width: 12px;
    height: 12px;
    display: inline-block;
    background: #d4d4d4;
    margin: 0 7px;
    border-radius: 50%;
  }
`;

export const PrimaryTitle = styled.h1`
    margin-top: 10px;
    margin-left: 10px;
    padding-left: 0px;
    color: #333;
    font-size: 25px;
    line-height: 1;
    font-weight: 700;
    position: relative;
    width: fit-content;

    &:after {
      content: '';
      width: 100px;
      height: 1px;
      background-color: #ffa100;
      color: #fadd12;
      position: absolute;
      right: -50px; 
      bottom: -10px;
    }

    @media ( max-width: 991px) {
      font-size: 25px;
    }

    @media ( max-width: 768px) {
      font-size: 20px;
      line-height: 1;

      &:after {
        right: -25px;
      }
    }
`;

export const BtnPrimary = styled(Link)`
  display: block;
  border-radius: 16px;
  background-color: transparent;
  color: #00985f;
  border: solid 1px #00985f;
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  width: fit-content;
  padding: 9px 38px;
  text-decoration: none;
  transition: ease all 500ms;
  margin: 30px auto;

  &:hover {
    background-color: #00985f;
    transform: translate(0px, -5px);
    color: #fff;
    text-decoration: none;
  }
`;

export const BtnSecondary = styled(Link)`
  display: block;
  border-radius: 16px;
  background-color: transparent;
  color: #ffa100;
  border: solid 1px #ffa100;
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  width: fit-content;
  padding: 9px 38px;
  margin: 20px 0;
  text-decoration: none;
  transition: ease all 500ms;

  &:hover {
    background-color: #ffa100;
    transform: translate(0px, -5px);
    color: #000;
    text-decoration: none;
  }
`;

export const BackHeader = styled.div`
  height: 70px;
`;

export const LoadMoreBtn = styled.button`
  display: block;
  border-radius: 16px;
  background-color: transparent;
  color: #00985f;
  border: solid 1px #00985f;
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  width: fit-content;
  padding: 10px 25px;
  text-decoration: none;
  transition: ease all 500ms;
  margin: 1.5rem auto;
  line-height:1

  &:hover {
    background-color: #00985f;
    transform: translate(0px, -5px);
    color: #fff;
    text-decoration: none;
  }
`;