import api from '../../api';

const fetchCursosConcluidos = (params) => api.get('/lms/course_stats?course_status=CONCLUIDO', {
    params: {
        ...params,
    },
    active: true
});

const fetchCursosIniciados = (params) => api.get('/lms/course_stats', {
    params: {
        ...params,
    },
    active: true
});

export {
    fetchCursosConcluidos,
    fetchCursosIniciados
};