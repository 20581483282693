import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PropTypes from 'prop-types';
import { BackHeader } from '../../styles/base';

const Base = ({ children }) => (
  <div className="main">
    <Header/>
    <BackHeader/>
    {children}
    <Footer/>
  </div>
);

Base.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Base;
