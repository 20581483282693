import React from 'react';
import Logo from '../../assets/images/logo-preta.png';
import { Link } from 'react-router-dom';
import { Image } from './styles';

const LogoMaisSaber = (props) => (
    <Link to={props.url}>
        <Image src={Logo} className='image' alt="Grupo Samba" />
    </Link>
);

export default LogoMaisSaber;