import React, { useState, useContext } from 'react';
import { Question, Alternatives, QuestionAlternative } from './style';
import HandleRefreshQuestions from './handleRefreshQuestion';


const QuestionOption = props => {
  const [ altOpen, setAltOpen ] = useState(false);
  const { setAnswers } = useContext(HandleRefreshQuestions);
    
  return(
    <Question>
      <h2 onClick={e => {setAltOpen(!altOpen)}}>Questão {props.i+1} <i className="fas fa-chevron-down"/></h2>
        
      <Alternatives className={`parent-acordeon ${altOpen ? 'alt-open':'alt-closed'}`}>
        <h3 dangerouslySetInnerHTML={{__html:props.question.title}}/>
        {props.question.exam_question_alternatives.map(alt => (
          <QuestionAlternative key={alt.id} onClick={e => setAnswers(props.question.id,alt.id)}>
            <input id={alt.id} name={`question-${props.question.id}`} type="radio"/>
            <label htmlFor={alt.id} dangerouslySetInnerHTML={{__html:alt.title}}/>
          </QuestionAlternative>
        ))}
      </Alternatives>
    </Question>
  )
};
export default QuestionOption;