import api from '../../api';

const fetchCurrentUser = () => api.get('/users/me');

async function fetchUser() {
  return api.get('/users/me');
}
async function fetchUserByName(name, extraParams) {
  return api.get(`users?enabled=true&name=${name}`, {
    ...extraParams,
  });
}

const updateCurrentUser = (id, params) => {
  return api.put(`/users/${id}`, params)
}

export {
  fetchCurrentUser,
  fetchUser,
  fetchUserByName,
  updateCurrentUser
};
