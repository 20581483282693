import styled from 'styled-components';

export const MaterialBox = styled.div`

    padding: 40px 31px;
    border-radius: 16px;
    background-color: #f8f8f8;
    min-height: 250px;
    margin: 0 -5px;

    @media ( max-width :991px) {
        margin: 0 -15px;
    }

    h2 {
        margin-bottom: 30px;
        color: #333;
        font-size: 25px;
        font-weight: 600;
    }
`;

export const MaterialFiles = styled.div`
    
        display: flex;
        flex-wrap: wrap;
   
`;
export const MaterialLink = styled.a`
    display: flex;
    margin:1%;
    padding: 20px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 10px 20px 0 rgba(14, 14, 14, 0.15);
    transition: transform 400ms ease, border 400ms ease;
    text-decoration: none;
    color: #000;


    &:hover {
        border: 1px solid #ddd;
        box-shadow: none;
        transform: scale(1) translate(0px, 6px);
        text-decoration: none;
        color: #000
    }
`;

export const FileIcon = styled.div`
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fa1212;
    font-size: 30px;

    i {
      margin: 0;
      padding: 0;
    }
`;

export const FileText = styled.div`
    width:75%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 40px;
`;