import styled from 'styled-components';

export const ProgressBox = styled.div`
    background: #fff;
    padding: 30px 30px 15px;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 0 1px 8px 0 #d6d6d6;

    @media ( max-width :991px) {
        padding: 20px 10px 2px;
    }
`;