import React from 'react';
import { Row, Col } from 'reactstrap';
import { ProfileInfoBox } from './style';

const ProfileInfo = (props) => (
  <Row className='w-100 m-0'>
    <Col xs={6} className='px-0 pr-3'>
      <ProfileInfoBox>
        <h3>{props.provas < 10 ? `0${props.provas}`:props.provas}</h3>
        <small>Provas realizadas</small>
      </ProfileInfoBox>
    </Col>
    <Col xs={6} className='px-2'>
      <ProfileInfoBox>
        <h3>{props.videos.toFixed()}h</h3>
        <small>Vídeos Assistidos</small>
      </ProfileInfoBox>
    </Col>
    {/* <Col xs={4} className='px-0 pl-3'>
      <ProfileInfoBox>
        <h3>{props.certificados < 10 ? `0${props.certificados}`:props.certificados}</h3>
        <small>Certificados</small>
      </ProfileInfoBox>
    </Col> */}
  </Row>
);


export default ProfileInfo;