import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import Lottie from "react-lottie";
import { PrimaryTitle } from "../../../styles/base";
import { BtnFinish, ProgresExam, Progress } from "./style";
import {
  fetchProvas,
  postProvas,
} from "../../../services/endpoints/lms/provas";
import QuestionOption from "../../../components/Course/Exam/question";
import HandleRefreshQuestions from "../../../components/Course/Exam/handleRefreshQuestion";
import Success from "../../../assets/jsons/animations/check-animation-green.json";
import Fail from "../../../assets/jsons/animations/error-cancelled.json";

const ProvaOnline = (props) => {
  const [exam, setExam] = useState({});
  const [examQuestions, setExamQuestions] = useState({});
  const [answersList, setAnswersList] = useState([]);
  const [responseExam, setResponseExam] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalError, setModalError] = useState(false);

  const examInfo = JSON.parse(localStorage.getItem("exam_info")) || "";

  useEffect(() => {
    fetchProvas(examInfo.exam_id)
      .then((response) => {
        setExam(response.data);
        if (
          response.data.hasOwnProperty("exam_questions") &&
          response.data.exam_questions.length > 0
        ) {
          setExamQuestions(response.data.exam_questions);
        }
      })
      .catch((err) => {
        window.location.replace("/");
      });
  }, [examInfo.exam_id]);

  const setAnswers = (exam_questions_id, exam_questions_alternative) => {
    const answersTemp = [];
    let exists = false;

    answersList.map((item) => {
      if (exists === false && item.exam_questions_id === exam_questions_id) {
        return (exists = true);
      }
      return answersTemp.push(item);
    });

    answersTemp.push({ exam_questions_id, exam_questions_alternative });
    setAnswersList(answersTemp);
  };

  function submitExam(id_exam) {
    if(answersList.length < examQuestions.length) {
      setModalError(true)
      return
    }

    postProvas(id_exam, answersList, examInfo.object_id)
      .then((res) => {
        setModal(true);
        setResponseExam(res.data);
      })
      .catch((err) => {
        console.error("Erro ao enviar questões: ", err);
      });
  }

  const options_success = {
    loop: true,
    autoplay: true,
    animationData: Success,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const options_fail = {
    loop: true,
    autoplay: true,
    animationData: Fail,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const toggle = () => {
    localStorage.removeItem("exam_info");
    window.location.replace(examInfo.redirect);
  };

  const toggleError = () => {
    setModalError(prev => !prev)
  }

  return (
    <div className="w-container my-2 px-1">
      <PrimaryTitle className="mt-4">{exam.title}</PrimaryTitle>
      <HandleRefreshQuestions.Provider value={{ setAnswers }}>
        {examQuestions.length > 0 &&
          examQuestions.map((question, i) => (
            <QuestionOption key={question.id} question={question} i={i} />
          ))}
      </HandleRefreshQuestions.Provider>

      <div className="w-100 d-flex justify-content-center align-items-center my-5">
        <BtnFinish 
          disabled={answersList.length < examQuestions.length} 
          onClick={(e) => submitExam(exam.id)}
        >
          Concluir
        </BtnFinish>
      </div>

      <Modal
        isOpen={modal}
        toggle={toggle}
        size="lg"
        centered
        className="modal-exam"
      >
        <i className="fas fa-times close-nota-modal" onClick={toggle} />
        {responseExam !== null && responseExam.approved ? (
          <div className="success-modal">
            <Lottie height={250} width={400} options={options_success} />
            <h1>Parabéns</h1>
            <p>
              {exam && !exam.mandatory_approval
                ? "Você finalizou o quiz"
                : `Você concluiu ${Math.round(
                    responseExam.percentage_correct_answers
                  )}% de pontos neste curso.`}
            </p>
          </div>
        ) : (
          <div className="fail-modal">
            <Lottie height={150} width={200} options={options_fail} />
            <h1>Você foi reprovado</h1>
            <p>Por favor, refaça a prova.</p>
          </div>
        )}
      </Modal>

      <ProgresExam>
        <div className="w-container d-flex align-items-center justify-content-around px-2 py-3">
          <h2>Progresso</h2>
          <Progress
            progress={(answersList.length * 100) / examQuestions.length}
          >
            <div />
          </Progress>
        </div>
      </ProgresExam>
      
      <Modal isOpen={modalError} toggle={toggleError} className='modal-curso-presencial ' centered={true}>
        <i className="fas fa-times close-classroom-modal" onClick={toggleError} />
        <p className='px-2 text-center'>Responda todas questões antes de concluir a prova</p>
      </Modal>
    </div>
  );
};

export default ProvaOnline;
