import React, { useState, useEffect } from 'react';
import { SideBar, SideBarItem } from './style';
import { fetchEvaluation, postEvaluation } from '../../../services/endpoints/general/evaluations';

const CourseSideBar = (props) => {
  const [favorite, setFavorite] = useState(false);
  const [like, setLike] = useState(false);

  const setEvaluation = (course_id, type) => {
    postEvaluation({
      "user_iri": atob(localStorage.getItem(`user-endpoint`)),
      "content_iri": `/lms/courses/${course_id}`,
      "value": 1,
      "type": type
    })
      .then(res => {
        res.data.type === "LIKE" ? setLike(!like) : setFavorite(!favorite);
      })
      .catch(err => {
        console.log('Erro ao favoritar', err)
      })
  }

  useEffect(() => {
    fetchEvaluation({
      content_iri: `/lms/courses/${props.course_id}`
    })
      .then(res => {
        if (res.data.length > 0) {
          res.data.map(avaliation => {
            if (avaliation.type === "LIKE" && avaliation.average === 1) {
              return setLike(true);
            } else if (avaliation.type === "FAVORITE" && avaliation.average === 1) {
              return setFavorite(true);
            }
            return null;
          })
        }
      })
      .catch(err => {
        console.log('Erro ao buscar avaliações', err)
      })
  }, []);

  return (
    <SideBar className='my-2 my-md-0'>
      {!!props.aulas &&
        <>
          <SideBarItem>
            <h3>{props.aulas < 10 ? `0${props.aulas}` : props.aulas}</h3>
            <p>{props.aulas === 1 ? 'Aula' : 'Aulas'}</p>
          </SideBarItem>
          <hr />
        </>
      }
      <SideBarItem onClick={e => setEvaluation(props.course_id, "LIKE")}>
        <h3><i className={`${like ? 'fas' : 'far'} fa-thumbs-up`} /></h3>
        <p>{like ? 'Curti' : 'Curtir'}</p>
      </SideBarItem>
      <hr />
      <SideBarItem onClick={e => setEvaluation(props.course_id, "FAVORITE")}>
        <h3><i className={`${favorite ? 'fas' : 'far'} fa-star`} /></h3>
        <p>{favorite ? 'Favorito' : 'Adc Favoritos'}</p>
      </SideBarItem>
    </SideBar>
  );
}
export default CourseSideBar;