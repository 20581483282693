import styled from 'styled-components';

export const Item = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #dadada;
    border-radius: 16px;
    box-shadow: none;
    transition: box-shadow 400ms ease, transform 400ms ease;
    padding: 5px 10px;
    max-width: 90vw;
    margin: 0 auto 10px;

    div {
        display: flex;
        align-items: center;
    }

    img {
        width: 30px;
        height: 30px;
        object-fit: cover;
        border-radius: 100%;
    }
`;