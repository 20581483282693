import React from 'react';
import { Row, Col } from 'reactstrap';
import { Image, CourseName, BtnAccess } from './style';


const ProgressCourse = (props) => (
   <Row className='d-flex align-items-center py-3 py-md-4 line-course-profile'>
       <Col xs={2} className='pr-0'>
            <Image src={props.image} alt={props.title}/>
       </Col>
       <Col xs={6} md={7}>
            <CourseName>{props.title}</CourseName>
       </Col>
       <Col xs={4} md={3}>
           <BtnAccess to={props.url}>Acessar</BtnAccess>
        </Col>
   </Row>
);

export default ProgressCourse;