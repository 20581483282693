import styled from 'styled-components';

export const ProgressBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    i {
        margin: 30px;
        color: #ffa100;
        font-size: 16px;
    }
`;