/** @format */

import api from "../../../api";

// const fetchCourses = (params = {}, orderBy = 'published_at', recommended = '', ) => {
//   return api.get(`/lms/courses${recommended !== ''? `/${recommended}` : ''}`, {
//     params: {
//       ...params,
//       active: true,
//       off_trails: true,
//       [`order[${orderBy}]`]: 'desc'
//     },
//   })
// };

const fetchCourses = (params) => {
  return api.get(`/lms/courses`, {
    params: {
      ...params,
      active: true,
      off_trails: true,
    },
  });
};

const fetchCoursesCategories = (params) => {
  return api.get(`/lms/categories/have_courses`, {
    params: {
      ...params
    },
  });
};

const fetchTotalCourses = (params) => {
  return api.get(`/lms/courses`, {
    params: {
      ...params,
      active: true,
    },
  });
};

const fetchCategories = (params) =>
  api.get(`/lms/categories`, {
    params: {
      ...params,
    },
  });

// const fetchCoursesCount = (recommended = '') => api.get(`/lms/courses/count_by${recommended !== ''? `/${recommended}` : ''}`);
const fetchCoursesCount = (params) => {
  return api.get(`/lms/courses/count_by`, {
    params: {
      ...params,
      active: true,
    },
  });
};

const fetchCoursesSearch = (search, params) =>
  api.get(`/lms/courses?order[published_at]=desc${search}`, {
    params: {
      ...params,
      active: true,
      off_trails: true,
    },
  });

const fetchCoursesInternal = (route, params = {}) =>
  api.get(`${route}`, {
    params: {
      ...params,
    },
  });

const setScormDetails = (body) => api.post("/lms/scorm_details", body);

const putScormStatus = (body, id) => api.put(`/lms/scorm_details/${id}`, body);

const setCourseStats = (body) => api.post("/lms/course_stats", body);

export {
  fetchCourses,
  fetchCoursesCategories,
  fetchTotalCourses,
  fetchCoursesInternal,
  fetchCoursesSearch,
  fetchCoursesCount,
  fetchCategories,
  setScormDetails,
  setCourseStats,
  putScormStatus,
};
