import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Routes from "./routes";
import userPlaceholder from "./assets/images/user_placeholder.png";
import "./assets/css/components.css";
import "./assets/css/normalize.css";
import "./assets/css/gruposoma_v3.css";
import "animate.css/animate.min.css";
import { Provider } from "react-redux";
import store from "./store";
import { Spinner } from "reactstrap";
import BlockedHome from "./pages/Home/BlockedHome";
import { getToken } from "./services/auth";
import { fetchUser } from "./services/endpoints/general/users";

function App() {
  const [hasEmail, setHasEmail] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (getToken()) {
      fetchUser()
        .then((response) => {
          localStorage.setItem(
            `user-endpoint`,
            btoa(`/users/${response.data.id}`)
          );
          localStorage.setItem(`user-id`, btoa(response.data.id));
          localStorage.setItem(`user-name`, btoa(response.data.name));
          localStorage.setItem(`user-roles`, btoa(response.data.roles));
          localStorage.setItem(
            `user-photo`,
            btoa(
              response.data.hasOwnProperty("Profile") &&
                response.data.profile.hasOwnProperty("avatar") &&
                response.data.profile.avatar.file != null
                ? response.data.profile.avatar.file.urlPublica
                : userPlaceholder
            )
          );
          localStorage.setItem(`user-email`, btoa(response.data.email));

          setHasEmail(
            response.data.email !== `${response.data.username}@somagrupo.com.br`
          );
          setLoading(true);
        })
        .catch((error) => {
          window.location = `${process.env.REACT_APP_SIGNIN_URL}?logout=${btoa(
            document.location.origin
          )}`;
          console.log("Erro ao armazenar dados de usuario no local", error);
        });
    } else {
      window.location.replace(
        `${process.env.REACT_APP_SIGNIN_URL}?logout=${btoa(
          document.location.origin
        )}`
      );
    }
  }, []);

  return (
    <Provider store={store}>
      {!loading ? (
        <div className="mt-30 mb-30 text-center">
          <Spinner color="dark" />
        </div>
      ) : hasEmail ? (
        <Routes />
      ) : (
            <BrowserRouter>
              <Switch>
                <Route path="/" component={BlockedHome} />
              </Switch>
            </BrowserRouter>
          )}
    </Provider>
  );
}

export default App;
