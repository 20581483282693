import React, { useEffect, useState } from "react";
import {
  MaterialBox,
  MaterialFiles,
  MaterialLink,
  FileIcon,
  FileText,
} from "./style";
import { Col, Row, Modal } from "reactstrap";
import { fetchSettings } from "../../../services/endpoints/settings/settings";

const CourseMaterial = (props) => {
  const [playerKey, setPlayerKey] = useState("");
  const [sambaReference, setSambaReference] = useState("");
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  useEffect(() => {
    fetchSettings()
      .then((res) => {
        setPlayerKey(
          res.data.filter((item) => item.id === "sambatech_player_key")[0].value
        );
      })
      .catch((error) => {
        console.log("Erro ao buscar configuração da chave do samba", error);
      });
  }, []);

  const getIcon = (material) => {
    let contenttype =
      material.hasOwnProperty("file") &&
        material.file &&
        material.file.hasOwnProperty("contenttype")
        ? material.file.contenttype
        : null;
    if (contenttype !== null) {
      switch (contenttype) {
        case "image/jpeg":
        case "image/jpg":
        case "image/png":
          return <i className="fal fa-file-image" />;
        case "video/mp4":
        case "video/avi":
          return <i className="fal fa-file-video" />;
        case "application/pdf":
          return <i className="fal fa-file-pdf" />;
        default:
          return <i className="fal fa-file-video" />;
      }
    } else {
      if (material && material.type) {
        return <i className="fal fa-file-video" />;
      }
    }
  };

  const getUrl = (material) => {
    let url =
      material.hasOwnProperty("file") &&
        material.file &&
        material.file.hasOwnProperty("urlPublica")
        ? material.file.urlPublica
        : null;
    if (url !== null) {
      return url;
    } else {
      return "#";
    }
  };

  const getIframe = (samba_media_reference) => {
    setSambaReference(samba_media_reference);
    setModal(true);
  };

  if (props.material[0].support_material === null) {
    return null;
  }

  return (
    <MaterialBox>
      <h2>Material de Apoio</h2>
      <MaterialFiles>
        <Row className="w-100">
          {props.material.map((lesson_material) => {
            if (lesson_material.support_material) {
              return (lesson_material.support_material.support_material_contents.map(
                (material) => (
                  <Col key={material.id} xs={12} md={3}>
                    {material.type === "SAMBA_MEDIA" ? (
                      <MaterialLink
                        href="#"
                        onClick={() => getIframe(material.samba_media_reference)}
                      >
                        <FileIcon>{getIcon(material)}</FileIcon>
                        <FileText>
                          {lesson_material.support_material.title}
                        </FileText>
                      </MaterialLink>
                    ) : (
                        <MaterialLink href={getUrl(material)} target="_blank">
                          <FileIcon>{getIcon(material)}</FileIcon>
                          <FileText>
                            {lesson_material.support_material.title}
                          </FileText>
                        </MaterialLink>
                      )}
                  </Col>
                )
              ))
            }
          })}
        </Row>
      </MaterialFiles>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="modal-curso-presencial"
        centered={true}
        size='lg'
      >
        <i className="fas fa-times close-classroom-modal" onClick={toggle} />
        <div className="embed-responsive embed-responsive-16by9">
          <iframe
            title={`${sambaReference} material`}
            allowfullscreen
            webkitallowfullscreen
            className="embed-responsive-item"
            mozallowfullscreen
            width="800"
            height="450"
            src={`https://fast.player.liquidplatform.com/pApiv2/embed/${playerKey}/${sambaReference}?startOutput=480p&enableShare=false&enableControls=true`}
            scrolling="no"
            frameborder="0"
          />
        </div>
      </Modal>
    </MaterialBox>
  );
};

export default CourseMaterial;
