import api from '../../api';

const fetchRewards = (params) => api.get('/gamification/rewards', {
    params: {
        ...params,
        active: true
    },
});

const fetchRedeem = (params) => api.get('/gamification/redeem', {
    params: {
        ...params,
    },
});

const getReward = (body) => api.post('/gamification/redeem/exchange', body);

export {
    fetchRewards,
    fetchRedeem,
    getReward
};