import api from '../../api';

const fetchUserAccess = (params) => api.get(`/general/user_access/from_profile`, {
  params: {
    ...params,
  },
});

export {
  fetchUserAccess,
};
