import React from 'react';
import { Row, Col } from 'reactstrap';
import { ProfileBox, ProfileName, BoxInterna, SubInfo } from './style';
import { Link } from 'react-router-dom';
import AvatarUpload from '../AvatarUpload';

const ProfileMainCard = (props) => {
    return (
        <ProfileBox>
            
            <AvatarUpload user={props.user} />

            <ProfileName>{props.name}</ProfileName>
            {props.user.profile.area !== undefined && (<SubInfo>Área: <b>{props.user.profile.area.value}</b></SubInfo>)}
            {props.user.profile.marca !== undefined && (<SubInfo>Marca: <b>{props.user.profile.marca.value}</b></SubInfo>)}
            {props.user.profile.centro_de_custo !== undefined && (<SubInfo>Centro de Custo: <b>{props.user.profile.centro_de_custo.value}</b></SubInfo>)}
            {props.user.profile.cargo !== undefined && (<SubInfo>Cargo: <b>{props.user.profile.cargo.value}</b></SubInfo>)}

            <Row className='w-100'>
                <Col xs={6}>
                    <BoxInterna>
                        <i className="fal fa-trophy-alt"/>
                        <small>Pontos</small>
                        <h3>{props.points}</h3>
                        {/* <Link to='/premios'>Ver Prêmios</Link> */}
                    </BoxInterna>
                </Col>
                <Col xs={6}>
                    <BoxInterna>
                        <i className="fal fa-medal"/>
                        <small>Classificação</small>
                        <h3>{props.classification}°</h3>
                        <Link to='/ranking'>Ver Ranking</Link>
                    </BoxInterna>
                </Col>
            </Row>
        </ProfileBox>
    );
}

export default ProfileMainCard;
