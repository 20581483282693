import React from 'react';
import Base from '../../theme/Base';
import Categories  from '../../components/Home/Categories'
import Recommended from '../../components/Home/Recommended';
import MostWatched from '../../components/Home/MostWatched';
import MainBanner from '../../components/Home/MainBanner';

const Home = (props) => {
  return (
    <Base>
      <MainBanner />
      <Categories />
      <Recommended />
      <MostWatched />
    </Base>
  );
};

export default Home;
