import React, { useState, useEffect } from 'react';
import CourseCard from '../Card';
import { ProgressBox } from './style';

const AulasProgress = (props) => {

    const [stats, setStats] = useState('');

    useEffect(() => {
        if (props.watched) {
            setStats({
                'status': 'completed',
                'icon': <i className="fal fa-check-circle" />
            })
        } else if (!props.watched && props.unlocked) {
            setStats({
                'status': 'unlock',
                'icon': <i className="far fa-lock-open-alt" />
            })
        } else {
            setStats({
                'status': 'lock',
                'icon': <i className="far fa-lock-alt" />
            })
        }
    }, [props])

    return (
        <ProgressBox className={`card-prog-${stats.status}`}>
            {stats.icon}
            <CourseCard
                image={props.image}
                url={props.url}
                title={props.title}
            />
        </ProgressBox>
    );
}

export default AulasProgress;