import React, { memo, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Script from "react-load-script";
import { Spinner } from "reactstrap";
import api from "../../services/api";
import { fetchSettings } from "../../services/endpoints/settings/settings";
import axios from "axios";

const SambaVideo = ({
  reference,
  updateStats,
  lesson_content_iri,
  id,
  last,
  hasExam,
  autoNextLesson,
  ...props
}) => {
  const mountedRef = useRef(true);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [playerKey, setPlayerKey] = useState("");
  const [progressVideo, setProgressVideo] = useState(false);
  const [flag, setFlag] = useState(true)

  const getCurrenteTime = async () => {
    try {
      const response = await api.get(
        `/lms/course_stats?wherein[course.id]=${props.courseStats.courseId}`
      );
      const { data } = response;

      if (
        data.length &&
        data[0].current_lesson_time !== null &&
        props.videoResume === true
      ) {
        return data[0].current_lesson_time;
      } else {
        return 0;
      }
    } catch (error) {
      return props.videoResume || 0;
    }
  };

  const setTrailsStats = (time, nextLesson) => {
    api.post(`/lms/trail_stats`, {
      trail: {
        id: props.trailStats.trailId,
      },
      user_iri: atob(localStorage.getItem(`user-endpoint`)),
      current_course: props.trailStats.currenCourse,
      current_lesson_course: nextLesson
        ? props.trailStats.currenteLesson + 1
        : props.trailStats.currenteLesson,
      current_lesson_course_time: time,
    });
  };

  const initCourseStats = async () => {
    try {
      const response = await api.get(
        `/lms/course_stats?wherein[course.id]=${props.courseStats.courseId}`
      );
      const { data } = response;

      if (data.length === 0) {
        api.post(`/lms/course_stats`, {
          completion_at: null,
          course: {
            id: props.courseStats.courseId,
          },
          course_status: "EM_ANDAMENTO",
          current_lesson: 0,
          current_lesson_time: 0,
          progress: 0,
          user_iri: atob(localStorage.getItem(`user-endpoint`)),
          watcheds_lessons: 0,
        });
      }
    } catch (error) {
      console.error("SambaVideo.initCourseStats", error);
    }
  };

  const setCourseStats = async (time, nextLesson) => {
    try {
      const response = await api.get(
        `/lms/course_stats?wherein[course.id]=${props.courseStats.courseId}`
      );
      const { data } = response;

      if (data.length) {
        api.put(`/lms/course_stats/${data[0].id}`, {
          current_lesson: nextLesson
            ? props.courseStats.currenteLesson + 1
            : props.courseStats.currenteLesson,
          current_lesson_time: time,
        });
      }
    } catch (error) {
      console.error("SambaVideo.setCourseStats", error);
    }
  };

  useEffect(() => {
    const setProgressInit = async () => {
      if (
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i)
      ) {
        setProgressVideo(false);
      } else {
        setProgressVideo(await getCurrenteTime());
      }
    };

    setProgressInit();

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    if (!mountedRef.current) return null;
    fetchSettings("", { cancelToken: source.token })
      .then((res) => {
        setPlayerKey(
          res.data.filter((item) => item.id === "sambatech_player_key")[0].value
        );
        setLoading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          console.log("Erro ao buscar configuração da chave do samba", error);
      });

    return () => {
      mountedRef.current = false;
      source.cancel();
    };
  }, []);

  const [load, setLoad] = useState(false);
  const [watchedId] = useState(lesson_content_iri);
  const player = "player";
  let sambaPlayer = false;
  let setProgress = false;

  if (load) {
    const eventListener = async (player) => {
      switch (player.event) {
        case "onLoad":
          break;
        case "onStart":
          if (props.courseStats) {
            initCourseStats();
          }
          if (watchedId === null) {
            api
              .post(`/general/watcheds`, {
                user_iri: atob(localStorage.getItem(`user-endpoint`)),
                progress: 0,
                content_iri: `/lms/lesson_contents/${id}`,
              })
              .then((resp) => {
                console.log("TEMPO INICIADO");
              });
          }
          break;
        case "onListen":
          if (
            !setProgress &&
            player.eventParam > 1 &&
            parseInt(player.eventParam) % 15 === 0
          ) {
            if (props.courseStats) {
              setCourseStats(player.eventParam, false);
            } else if (props.trailStats) {
              setTrailsStats(player.eventParam, false);
            }

            setProgress = true;
            api.post(`/general/watcheds`, {
              user_iri: atob(localStorage.getItem(`user-endpoint`)),
              progress: Math.round((player.eventParam * 100000) / player.duration),
              content_iri: `/lms/lesson_contents/${id}`,
            });

            setTimeout(() => {
              setProgress = false;
            }, 1000);
          }
          break;
        case "onPause":
          break;
        case "onResume":
          break;
        case "onSeek":
          break;
        case "onCuepoint":
          break;
        case "onMediaView":
          break;
        case "onProgress":
          break;
        case "onFinish":
          try {
            if(flag){
              const resp = await  api.post(`/general/watcheds`, {
                user_iri: atob(localStorage.getItem(`user-endpoint`)),
                progress: 100,
                content_iri: `/lms/lesson_contents/${id}`,
                watched: true,
              })
              if (props.trailStats && last && !hasExam) history.go();
              if (props.courseStats) {
                autoNextLesson();
                setCourseStats(0, true);
              } else if (props.trailStats) {
                setTrailsStats(0, true);
              }
              updateStats();
              autoNextLesson();
              setFlag(false)
          }

          } catch (error) {
            console.error(`SambaPlayer.onFinish`, error)
          }
          break;
        case "onError":
          break;
        default:
          break;
      }
    };

    if (!sambaPlayer) {
      sambaPlayer = new window.SambaPlayer(player, {
        ph: playerKey,
        m: reference,
        playerParams: {
          enableShare: false,
          resume: progressVideo || false,
          html5: true,
        },
        events: {
          "*": eventListener,
        },
      });
    }
  }
  return (
    <>
      {loading === true ? (
        <div className='video-loading'>
          <Spinner color='dark' />
        </div>
      ) : (
        <>
          <Script
            url='https://player.sambatech.com.br/v3/samba.player.api.js'
            onLoad={function () {
              setLoad(true);
            }}
          />
          <div
            className='embed-responsive embed-responsive-16by9'
            style={{ height: "100%" }}
          >
            <div
              className='embed-responsive-item'
              id='player'
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            ></div>
          </div>
        </>
      )}
    </>
  );
};

export default memo(SambaVideo);
