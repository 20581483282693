import styled from 'styled-components';

export const Footer = styled.footer`
  position: static;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  padding: 25px 0;
  background-color: #191919;
  color: #fff;

  @media (max-width: 768px) {
    padding: 2px;
  } 
`;

export const Image = styled.img`
  width: 140px;

  @media (max-width: 768px) {
    width: 100px;
  } 
`;

export const Text = styled.div`
  text-align: right;
  color: #ffffff;
  font-size: 11px;
`;

export const Line = styled.div`
  width: 1px;
  height: 40px;
  margin-right: 40px;
  margin-left: 40px;
  border: 0px solid transparent;
  background-color: #f8f8f8;
`;